
import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableHead, TableRow, Tooltip , TableCell } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadFileType } from '../../../utils';
import ReadMoreInline from '../../../shared_elements/components/ReadMoreInline';
import { globalGetService, globalExportService } from '../../../utils/globalApiServices';
import { checkApiStatus } from '../../../utils';
import { Info } from '@material-ui/icons';

const TemplateHD = ({ }) => {
    return (
        <TableHead style={{ position: "sticky", top: '0' }}>
            <TableRow>
                <TableCell width='250px'>Name</TableCell>
                <TableCell>Asset Type</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align='right'>Action</TableCell>
            </TableRow>
        </TableHead>
    )
}
const TemplateListItem = ({ listItem, setAddEdit, setDeleteModal, downloadTemplate }) => {
    let titleItems = listItem?.asset_type?.value == 1 ? listItem?.aircraft_types : listItem?.asset_type?.value == 2 ? listItem?.engine_types : listItem?.apu_types
    let tooltipContent = titleItems?.length && titleItems?.map((item, index) => { return index == 0 ? item.name : ', ' + item.name })
    return (
        <TableRow>
            <TableCell>
                {listItem?.name || '--'}
            </TableCell>
            <TableCell>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {listItem?.asset_type?.label || '--'}
                    {
                        tooltipContent?.length ?
                            <span>
                                <Tooltip title={tooltipContent}>
                                    <span>
                                        <Info style={{ marginLeft: '2px', height: '14px' }} />
                                    </span>
                                </Tooltip>
                            </span> : null
                    }
                </span>
            </TableCell>
            <TableCell style={{ maxWidth: '310px' }}>
                <ReadMoreInline text={listItem?.description} />
            </TableCell>
            <TableCell>
                <ul className='list-inline' style={{ float: 'right' }}>
                    <li className='list-inline-item' style={{ cursor: 'pointer' }}>
                        <Tooltip title={`Download Attachment ${listItem.name}`}>
                            <GetAppIcon onClick={() => downloadTemplate(listItem)} fontSize='small' color='primary' />
                        </Tooltip>
                    </li>
                </ul>
            </TableCell>
        </TableRow>)
}
const MultiTemplateModal = ({ open, handleClose }) => {

    const [loading, setLoading] = useState(false)
    const [listData, setListData] = useState({})
    useEffect(() => {
        getTemplateList()
    }, [])
    const getTemplateList = (query) => {
        setLoading(true)
        globalGetService(`records/v2/lessor-folder-template/`, query)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    setListData(response.data.data)
                }
            })
    }
    const downloadTemplate = (item) => {
        setLoading(true)
        globalExportService(`records/v2/lessor-folder-template/download/${item?.id}/`)
            .then(response => {
                setLoading(false)
                downloadFileType(response.data, (`${item?.name}`), 'xlsx');
            })
    }
    return (
        <Dialog open={open} onClose={handleClose} maxWidth='lg' aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Download Template </DialogTitle>
            <DialogContent style={{paddingTop:"0px"}}>
                <Table className='mui-table-format'>
                    <TemplateHD />
                    {listData?.list?.length ?
                        <TableBody>
                            {listData?.list?.map((listItem, index) => {
                                return (
                                    <TemplateListItem
                                        listItem={listItem}
                                        key={index}
                                        downloadTemplate={downloadTemplate}
                                    />)
                            })
                            }
                        </TableBody> :
                        <TableBody>
                            {loading ?
                                    <TableRow>
                                        <TableCell colSpan={4} align='center'><b>Loading...</b></TableCell>
                                    </TableRow> :
                            <TableRow>
                                <TableCell colSpan={4} align='center'>No Template available </TableCell>
                            </TableRow>
                            }
                        </TableBody>
                    }
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Okay
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default MultiTemplateModal;