import moment from 'moment';
import { globalGetService, globalPostService, globalPutService, globalDeleteService, globalFileUploadService, globalStaticGetService, globalExportService, globalDownloadService } from '../../utils/globalApiServices';
import { checkApiStatus, getLocalStorageInfo, downloadFileType } from '../../utils';
import { trackActivity } from '../../utils/mixpanel';
import { assetTypeValues, revAssetTypeValues } from '../../constants';
import { onCheckActionPermission } from '.';

export function exportAssetInventoryApi(props) {
  this.setState({ pageLoader: true });
  globalGetService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/report/inventory/`, { download: true })
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        trackActivity('Item Exported', {
          page_title: 'Dataroom Header',
          page_type: props.match.params.type,
          event_type: 'Export',
          event_desc: 'Exported Inventory from Dataroom Header',
          asset_name: props.recordsRepos.asset_name,
          asset_type: props.match.params.assetType,
          asset_slug: props.match.params.assetSlug,
        })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function getRecordsDetailApi(asset) {
  return new Promise(function (resolve, reject) {
    globalGetService(`console/${assetTypeValues[asset.asset_type].urlType}/${asset.slug}/records-detail/`)
      .then(response => {
        if (checkApiStatus(response)) {
          resolve(response)
        }
      })
  });
}
export function getRecordsDetailApiV_2(props) {
  globalGetService(`console/${props.match.params.assetType}/${props.match.params.assetSlug}/records-detail/`)
    .then(response => {
      if (checkApiStatus(response)) {
        if (Object.keys(response.data.data).length) {
          this.setState({ fileInfo: response.data.data });
        } else {
          this.props.enqueueSnackbar('File detail not found', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      }
    })
}


export function getDataRoomListApi(props, uuid, queryParams = {}, loaderType, sortHandlerFlag = false,getAnalytics=false) {
  if (queryParams.page && queryParams.page == 1) {
    this.setState({ [loaderType]: true });
  }
  globalGetService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/data-room/${uuid.trim()}/`, queryParams)
    .then(response => {
      this.setState({ [loaderType]: false });
      if (checkApiStatus(response)) {
        if (queryParams.page == 1) {
          this.setState({ dataRoom: response.data.data, sort_by: queryParams.sort_by ? queryParams.sort_by : 'asc' })
        } else {
          this.setState(prevState => ({
            ...prevState,
            dataRoom: {
              ...prevState.dataRoom,
              list: sortHandlerFlag ? [...response.data.data.list] : [...prevState.dataRoom.list, ...response.data.data.list],
              pagination: response.data.data.pagination
            },
            sort_by: queryParams.sort_by ? queryParams.sort_by : 'asc'
          }));
        }
        this.getDataRoomAnalyticApi(this.props, { folder_uuid: this.props.match.params.uuid, general_info: true })
        if (queryParams.search) {
          trackActivity('Item Searched', {
            page_title: 'Dataroom',
            asset_type: props.match.params.assetType,
            asset_slug: props.match.params.assetSlug,
            item_uuid: props.match.params.uuid,
            search_key: queryParams.search
          })
        } else {

        }
      }
    })
}

export function getFolderFilesApi(props, query) {
  globalGetService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/get-folder-files/${props.match.params.uuid}/`, query)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          folderLevelFiles: response.data.data,
          modal: true,
          title: query.unpublished ? 'Unpublished Files' : 'To Be Renamed Files'
        }))
      } else {

      }
    })
}
export function getDataRoomTreeListApi(props, uuid, queryParams) {
  globalGetService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/data-room/${uuid}/`, queryParams)
    .then(response => {
      if (checkApiStatus(response)) 
      {
        this.setState({ dataRoomTree: response.data.data.list })
      }
    })
}

export function getDataRoomAnalyticApi(props, queryParams) {
  globalStaticGetService(`records/v2/dashboard_line_plots/`, queryParams)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({ analyticStats: response.data.data });
      }
    })
}
export function uploadFileCheckerApi(props, files) {
  let fileList = Array.from(files).map((file) => { return { name: file.name, path: file.path } })
  // let fileNames = [];
  // let folderFlag = false
  // for (let i = 0; i < files.length; i++) {
  //   let file = files[i];
  //   if(file.path.includes('/')){
  //     folderFlag = true;
  //     break;
  //   }
  //   fileNames.push(file.name);
  // }
  return new Promise(function (resolve, reject) {
    globalPostService(`records/v2/file-name-checker/${props.match.params.uuid}/`, { files: fileList })
      .then(response => {
        if (checkApiStatus(response)) {
          resolve(response);
        }
      })
  });
  // if(folderFlag){
  //   this.props.enqueueSnackbar('Folder Upload is not supported, please use Drive Tool to upload large data',{variant:'error',anchorOrigin:{vertical: 'top',horizontal: 'right'}})
  // }else{
  //
  // }
}

export function downloadDataRoomApi(props, data) {
  let payload = { folders_uuid: [], files_uuid: [] };
  let selectesAsset = this.props.allAssets.find(asset => asset.slug === this.props.match.params.assetSlug && asset.asset_type === revAssetTypeValues[this.props.match.params.assetType].assetType)
  data.map(rowItem => {
    trackActivity('File/Folder Downloaded', { item_name: rowItem.name, item_type: rowItem.type, item_source: this.state.dataRoom.navigation[this.state.dataRoom.navigation.length - 1].name, asset: selectesAsset && selectesAsset.unique_name ? selectesAsset.unique_name : '' })
    if (rowItem.type === 'folder') {
      payload = {
        ...payload,
        folders_uuid: [...payload.folders_uuid, rowItem.uuid]
      }
    } else {
      payload = {
        ...payload,
        files_uuid: [...payload.files_uuid, rowItem.uuid]
      }
    }
  })

  this.setState({ pageLoader: true });
  globalPostService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/data-room/download/`, payload)
    .then(response => {
      this.setState({ pageLoader: false });
      // let assetName = ''
      // if(this.state.asset.asset_type === 1){
      //   assetName = 'MSN ' + this.state.asset.msn
      // }else if(this.state.asset.asset_type === 3){
      //   assetName = 'APU ' + this.state.asset.serial_number
      // }else if(this.state.asset.asset_type === 4){
      //   assetName = 'LG ' + this.state.asset.serial_number
      // }else if(this.state.asset.asset_type === 5){
      //   assetName = 'PROPELLER ' + this.state.asset.serial_number
      // }if(this.state.asset.asset_type === 2){
      //   assetName = 'ESN ' + this.state.asset.esn
      // }else{
      //   assetName = this.state.asset.esn
      // }
      // downloadFileType(response.data, assetName,'zip')
      // this.trackFileDownloadActivity(data.uuid)
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          selectedItems: {
            ...prevState.selectedItems,
            ids: []
          }
        }))
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}

export function downloadDataRoomApiV2(props, data) {
  let payload = { folders_uuid: [], files_uuid: [] };
  let selectesAsset = this.props.allAssets.find(asset => asset.slug === this.props.match.params.assetSlug && asset.asset_type === revAssetTypeValues[this.props.match.params.assetType].assetType)
  data.map(rowItem => {
//    trackActivity('File/Folder Downloaded', { item_name: rowItem.name, item_type: rowItem.type, item_source: this.state.dataRoom.navigation[this.state.dataRoom.navigation.length - 1].name, asset: selectesAsset && selectesAsset.unique_name ? selectesAsset.unique_name : '' })
    if (rowItem.type === 'folder') {
      payload = {
        ...payload,
        folders_uuid: [...payload.folders_uuid, rowItem.uuid]
      }
    } else {
      payload = {
        ...payload,
        files_uuid: [...payload.files_uuid, rowItem.uuid]
      }
    }
  })

  this.setState({ showDownloadv2: 1 });
  globalPostService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/download_v2/`, payload)
  .then(response => {
      this.setState({ showDownloadv2: 2 });
      setTimeout(()=>{
        this.setState({ showDownloadv2: 0 });
      },5000)
      // this.setState({ pageLoader: false });
      // let assetName = ''
      // if(this.state.asset.asset_type === 1){
      //   assetName = 'MSN ' + this.state.asset.msn
      // }else if(this.state.asset.asset_type === 3){
      //   assetName = 'APU ' + this.state.asset.serial_number
      // }else if(this.state.asset.asset_type === 4){
      //   assetName = 'LG ' + this.state.asset.serial_number
      // }else if(this.state.asset.asset_type === 5){
      //   assetName = 'PROPELLER ' + this.state.asset.serial_number
      // }if(this.state.asset.asset_type === 2){
      //   assetName = 'ESN ' + this.state.asset.esn
      // }else{
      //   assetName = this.state.asset.esn
      // }
      // downloadFileType(response.data, assetName,'zip')
      // this.trackFileDownloadActivity(data.uuid)
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          selectedItems: {
            ...prevState.selectedItems,
            ids: []
          }
        }))
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}


export function trackFileDownloadActivity(uuid) {
  globalGetService(`/records/file_download_activity_update/${uuid}/?device=web`)
    .then(response => {
    })
}

export function downloadGlobalSearchApi(props, assetSlug, assetType, data) {
  let payload = {};
  if (data.type === 'folder') {
    payload = {
      ...payload,
      folders_uuid: [data.uuid]
    }
  } else {
    payload = {
      ...payload,
      files_uuid: [data.uuid]
    }
  }
  this.setState({ pageLoader: true });
  globalPostService(`records/v2/${assetType}/${assetSlug}/data-room/download/`, payload)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        // this.trackFileDownloadActivity(data.uuid)
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function downloadGlobalSearchApiV2(props, assetSlug, assetType, data) {
  let payload = {};
  if (data.type === 'folder') {
    payload = {
      ...payload,
      folders_uuid: [data.uuid]
    }
  } else {
    payload = {
      ...payload,
      files_uuid: [data.uuid]
    }
  }
  this.setState({ pageLoader: true });
  globalPostService(`records/v2/${assetType}/${assetSlug}/data-room/download/`, payload)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        // this.trackFileDownloadActivity(data.uuid)
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function uploadFileDataApi(props, fileIndex = 0) {
  let uploadFiles = new FormData();
  uploadFiles.append('file_' + (parseInt(fileIndex) + 1), this.props.fileStacks[fileIndex]);
  uploadFiles.append('path_' + (parseInt(fileIndex) + 1), this.props.fileStacks[fileIndex].path);
  var config = {
    onUploadProgress: function (progressEvent) {
      var percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    }
  }
  this.props.toggleDataUploadProgress(true);
  let selectesAsset = this.props.allAssets.find(asset => asset.slug === this.props.match.params.assetSlug && asset.asset_type === revAssetTypeValues[this.props.match.params.assetType].assetType)
  globalFileUploadService(`records/v2/data-room/${props.match.params.uuid}/upload-files/`, uploadFiles, config)
    .then(response => {
      if (checkApiStatus(response)) {
        trackActivity('File(s)/Folder(s) Uploaded', { item_name: this.props.fileStacks[fileIndex]?.name, item_path: this.props.fileStacks[fileIndex].path, item_size: this.props.fileStacks[fileIndex].size, item_destination: this.state.dataRoom.navigation[this.state.dataRoom.navigation.length - 1]?.name, asset: selectesAsset && selectesAsset.unique_name ? selectesAsset.unique_name : '', item_count: props.fileStacks.length ? props.fileStacks.length : '0' })
        this.props.updateAfterFileStacks({ name: this.props.fileStacks[fileIndex].name, path: this.props.fileStacks[fileIndex].path, status: 1 });
        if (fileIndex === this.props.fileStacks.length - 1) {
          this.props.toggleDataUploadProgress(false);
          this.getDataRoomListApi(this.props, this.props.match.params.uuid, { sort: 'name', sort_by: 'asc', page: 1, per_page: 10 }, 'pageLoader');
          this.getDataRoomTreeListApi(this.props, this.props.match.params.uuid, { root_structure: true, sort: 'name', sort_by: 'asc' })
          this.props.getRecordsRepos(this.props);
          this.setState({ uploadFileIssue: { modal: false } })
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          fileIndex++
          this.uploadFileDataApi(props, fileIndex);
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      } else if ([5000, 5001, 5002].includes(response.data.statusCode)) {
        this.setState({
          licenceModalFlag: true,
          licenceModalMessage: response.data.message
        })

      } else {
        if (fileIndex < this.props.fileStacks.length) {
          fileIndex++
          this.uploadFileDataApi(props, fileIndex);
        }
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function shareDataRoomDataApi(props, data, type) {
  // data = { type: 'file|folder', uuid:'',share: 'true|false',hour:'' }
  this.setState({ pageLoader: true });
  globalPostService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/data-room/share/`, data)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        if (data.share === true) {
          trackActivity('Share Link Enabled', { page_title: 'Dataroom', item_type: data.type, uuid: data.uuid, share_key: response.data.data.share_key, valid_till: response.data.data.valid_till })
        } else {

        }
        if (type) {
          this.setState(prevState => ({
            ...prevState,
            shareContent: {
              ...prevState.shareContent,
              data: {
                ...prevState.shareContent.data,
                share_enabled: data.share,
                share_key: response.data.data.share_key ? response.data.data.share_key : '',
                valid_till: response.data.data.valid_till
              }
            }
          }));
          if (type === 'minipack') {
            this.getMiniPackAssemblyApi(this.props, {}, 'pageLoader');
          } else {
            this.getResourcesKitFilesApi(this.props, { tab: this.props.match.params.kitType }, 'pageLoader');
          }
        } else {
          this.setState(prevState => ({
            ...prevState,
            dataRoom: {
              ...prevState.dataRoom,
              list: prevState.dataRoom.list.map(item => item.type === data.type && item.uuid === data.uuid ? {
                ...item,
                share_enabled: data.share,
                share_key: response.data.data.share_key ? response.data.data.share_key : '',
                valid_till: response.data.data.valid_till
              } : item)
            },
            shareContent: {
              ...prevState.shareContent,
              data: {
                ...prevState.shareContent.data,
                share_enabled: data.share,
                valid_till: response.data.data.valid_till,
                share_key: response.data.data.share_key ? response.data.data.share_key : ''
              }
            }
          }));
        }
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        if (this.state.isRootNavigation) {
          this.componentDidMount()
        }
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}

export function createNewFolderApi(props, data) {
  this.setState({ pageLoader: true });
  globalPostService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/${props.match.params.uuid}/create-folder/`, data)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          dataRoom: {
            ...prevState.dataRoom,
            list: [response.data.data, ...prevState.dataRoom.list]
          },
          newFolderModal: false,
          selectedItems: {
            ...prevState.selectedItems,
            ids: []
          }
        }));
        this.getDataRoomTreeListApi(this.props, this.props.match.params.uuid, { root_structure: true });
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        trackActivity('Folder Added', { page_title: 'Dataroom', page_type: props.match.params.type, folder_name: data.name, asset_slug: props.match.params.assetSlug, asset_type: props.match.params.assetType, uuid: props.match.params.uuid, path: props.match.url })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}



// Data Room Context Manu Related API
/**
  - Data Room Move Feature
**/
export function getFodlerCollabUserApi(props, uuid) {
  globalGetService(`records/v2/user-folder-share/${uuid}/`)
    .then(response => {
      if (checkApiStatus(response)) {
        if (this.state.isRootNavigation) {
          this.setState(prevState => ({
            ...prevState,
            shareContent: {
              ...prevState.shareContent,
              data: {
                ...prevState.shareContent.data,
                userEmails: response.data.data,
                share_enabled: response.data.global_share_link_details.share_enabled,
                share_duration: response.data.global_share_link_details.share_duration,
                share_key: response.data.global_share_link_details.share_key
              }
            }
          }));
        } else {
          this.setState(prevState => ({
            ...prevState,
            shareContent: {
              ...prevState.shareContent,
              data: {
                ...prevState.shareContent.data,
                userEmails: response.data.data
              }
            }
          }));
        }
      }
    })
}
export function inviteFolderCollabApi(props, uuid, data) {
  // { email:[], can_edit:true}
  this.setState({ formSubmitLoader: true });
  globalPostService(`records/v2/user-folder-share/${uuid}/`, data)
    .then(response => {
      this.setState({ formSubmitLoader: false, modal: false }, this.props.toggleModal());
      if (checkApiStatus(response)) {
        window.location.reload()
        trackActivity('File/Folder Collaborated', { page_title: 'Dataroom', permission: data.can_edit === 1 ? 'Edit, Add and Delete' : data.can_edit === 2 ? 'View Only' : data.can_edit === 3 ? 'View and Download' : '', email: data.email, reason: data.reason ? data.reason : '', uuid: uuid })
        this.setState({
          emails: [],
          accessType: { label: 'Invite As Editor', value: '1' },
          message: ''
        });
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function getParentuuidApi(asset, type) {
  let assetType = ''
  let assetSlug = ''
  assetType = assetTypeValues[asset?.asset_type]?.urlType
  assetSlug = asset?.slug
  return new Promise(function (resolve, reject) {
    globalGetService(`records/v2/${assetType}/${assetSlug}/get-asset-root-folder/`,{category:type})
    .then(response => {
        resolve(response)
      })
  })
}     


export function getParentuuid(asset) {
  let assetType = ''
  let assetSlug = ''
  assetType = assetTypeValues[asset.asset_type].urlType
  assetSlug = asset.slug
  globalGetService(`records/v2/${assetType}/${assetSlug}/get-asset-root-folder/`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({ parentUuid: response.data.data && response.data.data.technical_records ? response.data.data.technical_records.uuid : '' })
      }
      else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function getMoveFoldersApi(props, uuid, type, parentuuid, name, moveType, value, copyType=false) {
  const { selectedAsset } = this.state
  let assetType = ''
  let assetSlug = ''
  if (value) {
    assetType = assetTypeValues[value.asset_type].urlType
    assetSlug = value.slug
  }
    assetType = props.match.params.assetType
    assetSlug = props.match.params.assetSlug


  if (this.state.parentUuid) {
    parentuuid = this.state.parentUuid
  }
  if (selectedAsset) {
    assetSlug = selectedAsset.slug
    assetType = assetTypeValues[selectedAsset.asset_type].urlType
  }

  // this.getParentuuid(assetType,assetSlug)
  this.setState({ pageLoader: true });
  globalGetService(`records/v2/${assetType}/${assetSlug}/data-room/move-folder-dropdown/${type}/${uuid}/${parentuuid}/`)
    .then(response => {
      this.setState({ pageLoader: false });
      parentuuid = parentuuid
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          moveData: {
            ...prevState.moveData,
            modal: true,
            sourceType: type,
            sourceId: uuid,
            moveFolderuuid: '',
            navigation: response.data.data.navigation,
            list: response.data.data.list,
            name: name,
            moveType: moveType
          }
        }))
        this.getDataRoomListApi(this.props, this.props.match.params.uuid, { sort: 'name', sort_by: 'asc', page: 1, per_page: 10 }, 'pageLoader');
        this.getDataRoomTreeListApi(this.props, this.props.match.params.uuid, { root_structure: true, sort: 'name', sort_by: 'asc' })
      }
    })
}
export function getMoveBackFoldersApi(props, uuid, type, parentuuid, name, moveType) {
  this.setState({ pageLoader: true });
  globalGetService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/data-room/move-folder-dropdown/${type}/${uuid}/${parentuuid}/`)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          moveData: {
            ...prevState.moveData,
            modal: true,
            sourceType: type,
            sourceId: uuid,
            moveFolderuuid: '',
            navigation: response.data.data.navigation,
            list: response.data.data.list,
            name: name,
            moveType: moveType
          }
        }))
        this.getDataRoomListApi(this.props, this.props.match.params.uuid, { sort: 'name', sort_by: 'asc', page: 1, per_page: 10 }, 'pageLoader');
        this.getDataRoomTreeListApi(this.props, this.props.match.params.uuid, { root_structure: true, sort: 'name', sort_by: 'asc' })
      }
    })
}


export function onMoveDmDataApi(props, data) {
  const { selectedItems, selectedAsset } = this.state;
  let payload = [];
  if (selectedAsset) {
    if (data.moveType === 'single') {
      payload = [...payload, { type: data.sourceType, to_folder: data.moveFolderuuid, uuid: [data.sourceId], asset_details: selectedAsset }];
    } else {
      let files_uuid = selectedItems.ids.filter(item => item.type === 'file'), folders_uuid = selectedItems.ids.filter(item => item.type === 'folder');
      if (files_uuid.length) {
        payload = [...payload, { type: 'file', to_folder: data.moveFolderuuid, uuid: files_uuid.map(ids => ids.uuid), asset_details: selectedAsset }];
      }
      if (folders_uuid.length) {
        payload = [...payload, { type: 'folder', to_folder: data.moveFolderuuid, uuid: folders_uuid.map(ids => ids.uuid), asset_details: selectedAsset }];
      }
    }
  }
  else {
    if (data.moveType === 'single') {
      payload = [...payload, { type: data.sourceType, to_folder: data.moveFolderuuid, uuid: [data.sourceId] }];
    } else {
      let files_uuid = selectedItems.ids.filter(item => item.type === 'file'), folders_uuid = selectedItems.ids.filter(item => item.type === 'folder');
      if (files_uuid.length) {
        payload = [...payload, { type: 'file', to_folder: data.moveFolderuuid, uuid: files_uuid.map(ids => ids.uuid) }];
      }
      if (folders_uuid.length) {
        payload = [...payload, { type: 'folder', to_folder: data.moveFolderuuid, uuid: folders_uuid.map(ids => ids.uuid) }];
      }
    }
  }
  if(onCheckActionPermission({},data?.navigation?.length, data?.moveFolderuuid, data?.navigation[0]?.uuid)){
  this.setState({ pageLoader: true });
  if(this.state?.copyType){
    globalPostService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/data-room/copy/`, payload)
    .then(response => {
      this.setState({ pageLoader: false});
      if (checkApiStatus(response)) {
        this.setState({ copyType:false });
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        this.setState({moveData: {name: '',moveFolderuuid: '',modal: false,sourceId: '',sourceType: '',navigation: [],list: []},selectedItems: { ids: [] }
        });
        this.getDataRoomListApi(this.props, this.props.match.params.uuid, { sort: 'name', page: 1, sort_by: 'asc' }, 'pageLoader');
        this.getDataRoomTreeListApi(this.props, this.props.match.params.uuid, { root_structure: true, sort: 'name', sort_by: 'asc' })
        trackActivity('File/Folder Copied', { page_title: 'Dataroom', asset_type: props.match.params.assetType, asset_slug: props.match.params.assetSlug, item_type: data.sourceType, target_name: data.name, source_uuid: data.sourceId, target_uuid: data.moveFolderuuid })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
  }
  else{
  globalPostService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/data-room/move/`, payload)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        this.setState({
          moveData: {
            name: '',
            moveFolderuuid: '',
            modal: false,
            sourceId: '',
            sourceType: '',
            navigation: [],
            list: []
          },
          selectedItems: { ids: [] }
        });
        this.getDataRoomListApi(this.props, this.props.match.params.uuid, { sort: 'name', page: 1, sort_by: 'asc' }, 'pageLoader');
        this.getDataRoomTreeListApi(this.props, this.props.match.params.uuid, { root_structure: true, sort: 'name', sort_by: 'asc' })
        trackActivity('File/Folder Moved', { page_title: 'Dataroom', asset_type: props.match.params.assetType, asset_slug: props.match.params.assetSlug, item_type: data.sourceType, target_name: data.name, source_uuid: data.sourceId, target_uuid: data.moveFolderuuid })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
  }
  }
  else{
    this.props.enqueueSnackbar('Root folder changes are restricted.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
  }
}
export function getDMDataPropertiesApi(props, data) {

  /**
    - Fetch the Properties of Folder and File Detail
  **/
  this.setState({ pageLoader: true });
  globalPostService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/get-properties/`, data)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.setState({ dataProperties: { open: true, data: response.data.data } });
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}

export function changeDMFilesStatusApi(props, data, refresh) {
  /**
    - Publish the files = publish => status=3
    - Unpublish the files = unpublish => status=1
    - Reject the files = reject => status=4
  **/
  const opsType = data.status === 4 ? 'reject' : 'publish'
  this.setState({ pageLoader: true });
  globalPostService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/data-room/status-change/${opsType}/`, data)
    .then(response => {
      this.setState({ pageLoader: false, selectedItems: { ids: [] } });
      this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      if (checkApiStatus(response)) {
        if (refresh) {
          window.location.reload();
        } else {
          // this.getDataRoomAnalyticApi(this.props, { folder_uuid: this.props.match.params.uuid })
          if (data.status !== 4) {
            this.setState(prevState => ({
              ...prevState,
              dataRoom: {
                ...prevState.dataRoom,
                list: prevState.dataRoom.list.map(item => item.type === 'file' && data.uuid.includes(item.uuid) ? {
                  ...item, status: data.status === 3 ? 'Published' : 'Uploaded'
                } : item)
              }
            }));
          } else {
            this.setState(prevState => ({
              ...prevState,
              dataRoom: {
                ...prevState.dataRoom,
                list: prevState.dataRoom.list.filter(item => !(item.type === 'file' && data.uuid.includes(item.uuid)))
              }
            }))
          }
        }

      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}

export function onDeleteDMDataApi(props, data) {
  /**
    - Change the Status of Files to Publish
  **/
  // let payload = Object.assign({}, data);
  let payload = { delete_status: 1, file_uuid: [], folder_uuid: [] };
  let selectesAsset = this.props.allAssets.find(asset => asset.slug === this.props.match.params.assetSlug && asset.asset_type === revAssetTypeValues[this.props.match.params.assetType].assetType)
  data.map(rowItem => {
    trackActivity('File/Folder Deleted', { item_name: rowItem.name, item_type: rowItem.type, Item_source: this.state.dataRoom.navigation[this.state.dataRoom.navigation.length - 1].name, asset_name: selectesAsset && selectesAsset.unique_name ? selectesAsset.unique_name : '', uuid: props.match.params.uuid })
    if (rowItem.type === 'file') {
      payload = {
        ...payload,
        file_uuid: [...payload.file_uuid, rowItem.uuid]
      }
    } else {
      payload = {
        ...payload,
        folder_uuid: [...payload.folder_uuid, rowItem.uuid]
      }
    }
  })
  this.setState({ pageLoader: true });
  globalPostService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/data-room/status-change/delete/`, payload)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.props.getRecordsRepos(props)
        this.componentDidMount()
        this.setState(prevState => ({
          ...prevState,
          dataRoom: {
            ...prevState.dataRoom,
            list: prevState.dataRoom.list.filter(item => !data.find(rowItem => rowItem.type === item.type && rowItem.uuid === item.uuid))
          },
          selectedItems: {
            ...prevState.selectedItems,
            ids: []
          }
        }))
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}

export function onRenameDMFilesApi(props, data) {

  // let selectesAsset = this.props.allAssets.find(asset => asset.slug === this.props.match.params.assetSlug && asset.asset_type === revAssetTypeValues[this.props.match.params.assetType].assetType)
  globalPostService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/data-room/rename/`, data)
    .then(response => {
      if (checkApiStatus(response)) {
        trackActivity('File/Folder Renamed(CTA)', { page_title: 'Dataroom', page_type: 'Rename Button', asset_type: props.match.params.assetType, asset_slug: props.match.params.assetSlug, item_type: data.type, new_name: data.new_name, uuid: data.uuid })
        this.setState(prevState => ({
          ...prevState,
          folderLevelFiles: {
            ...prevState.folderLevelFiles,
            list: data.publish ? prevState.folderLevelFiles.list.filter(item => item.uuid !== data.uuid) : prevState.folderLevelFiles.list.map(item => item.uuid === data.uuid ? { ...item, name: data.new_name } : item),
            pagination: {
              ...prevState.folderLevelFiles.pagination,
              total: data.publish ? prevState.folderLevelFiles.pagination.total - 1 : prevState.folderLevelFiles.pagination.total
            }
          },
          renameModal: false,
          fileInfo: null,
        }));
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    });
}
export function onRenameDMDataApi(props, dataRename, name) {
  /**
    - Change the Status of Files to Publish
  **/
  if (name.trim().length) {
    let payload = Object.assign({}, dataRename.data)
    payload = {
      ...payload,
      new_name: name
    }
    delete payload.name;
    let selectesAsset = this.props.allAssets.find(asset => asset.slug === this.props.match.params.assetSlug && asset.asset_type === revAssetTypeValues[this.props.match.params.assetType].assetType)
    // trackActivity('File/Folder Renamed', { ...payload, asset: selectesAsset.unique_name })
    this.setState({ pageLoader: true });
    globalPostService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/data-room/rename/`, payload)
      .then(response => {
        this.setState({ pageLoader: false });
        if (checkApiStatus(response)) {
          this.setState(prevState => ({
            ...prevState,
            dataRoom: {
              ...prevState.dataRoom,
              list: prevState.dataRoom.list.map(item => item.uuid === dataRename.data.uuid && item.type === dataRename.data.type ? {
                ...item, name: name
              } : item)
            },
            selectedItems: {
              ...prevState.selectedItems,
              ids: []
            }
          }));
          this.setState({ dataRename: { modal: false, data: null } });
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          trackActivity('File/Folder Renamed', { page_title: 'Dataroom', asset_name: selectesAsset && selectesAsset.unique_name ? selectesAsset.unique_name : '', asset_slug: props.match.params.assetSlug, asset_type: props.match.params.assetType, old_name: dataRename.data.name, new_name: name, type: dataRename.data.type, uuid: dataRename.data.uuid })
           window.location.reload()
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
  } else {
    this.props.enqueueSnackbar('Please enter Name', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
  }
}


// .End of Data Room Context Manu Related API



// Resources MiniPack, Kits and Other Api

export function getReourcesKitsFolderApi(props) {
  globalGetService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/get-kit-folder-details/`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({ resourcesKits: response.data.data });
      }
    })
}


export function getMiniPackAssemblyApi(props, queryParams, loaderType) {
  this.setState({ [loaderType]: true });
  globalGetService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/get-minipack-sub-folders/`)
    .then(response => {
      this.setState({ [loaderType]: false });
      if (checkApiStatus(response)) {
        this.setState({ miniPacks: response.data.data })
        trackActivity('Page Visited', {
          page_title: 'Mini Packs'
        })
      }
    })
}

export function getMiniPackDetailApi(props, queryParams = {}, loaderType) {
  this.setState({ [loaderType]: true });
  globalGetService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/resources-kit/get-files/`, queryParams)
    .then(response => {
      this.setState({ [loaderType]: false });
      if (checkApiStatus(response)) {
        this.setState({ dataKits: response.data.data })
      }
    })
}

export function onChangeMiniPackCoverPicApi(props, files, type, id) {
  let formData = new FormData();
  this.setState({ pageLoader: true });
  if (type === 'add') {
    formData.append('cover_photo', files[0]);
    globalPostService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/folder/${props.match.params.uuid}/minipack-cover-photo/`, formData)
      .then(response => {
        this.setState({ pageLoader: false });
        if (checkApiStatus(response)) {
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          this.setState({ miniPackCoverPic: { modal: false, file: null } });
          this.getMiniPackDetailApi(this.props, { tab: 'minipack', folder_uuid: this.props.match.params.uuid }, 'pageLoader')
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
  }
  if (type === 'change') {
    formData.append('cover_photo', files[0]);
    globalPutService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/folder/${props.match.params.uuid}/minipack-cover-photo/${id}/`, formData)
      .then(response => {
        this.setState({ pageLoader: false });
        if (checkApiStatus(response)) {
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          this.setState({ miniPackCoverPic: { modal: false, file: null } });
          this.getMiniPackDetailApi(this.props, { tab: 'minipack', folder_uuid: this.props.match.params.uuid }, 'pageLoader')
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
  }
  if (type === 'remove') {
    globalDeleteService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/folder/${props.match.params.uuid}/minipack-cover-photo/${id}/`)
      .then(response => {
        this.setState({ pageLoader: false });
        if (checkApiStatus(response)) {
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          this.setState({ miniPackCoverPic: { modal: false, file: null } });
          this.getMiniPackDetailApi(this.props, { tab: 'minipack', folder_uuid: this.props.match.params.uuid }, 'pageLoader')
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
  }
}

export function removeMiniPackFilesApi(props, data) {
  let payload = {};
  payload = {
    ...payload,
    category: 3,
    remove_all: data.title === 'Remove All' ? true : false,
    to_folder: props.match.params.uuid,
    uuid: data.ids,
    flag: false,
    type: 'file'
  }
  this.setState({ pageLoader: true });
  globalPostService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/data-room/status-change/category/`, payload)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        this.setState({ bulkOperation: { ids: [], modal: false } });
        this.getMiniPackDetailApi(props, { tab: 'minipack', folder_uuid: props.match.params.uuid }, 'pageLoader')
        trackActivity('File(s) Removed', { page_title: "Minipacks", remove_all: data.title === 'Remove All' ? true : false, to_folder: props.match.params.uuid, uuid: data.ids, multi: data.multi })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}


export function getResourcesKitFilesApi(props, queryParams = {}, loaderType) {
  /**
    - Fetch the Marketing and Engineering Kit Listing
  **/
  this.setState({ [loaderType]: true });
  globalGetService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/resources-kit/get-files/`, queryParams)
    .then(response => {
      this.setState({ [loaderType]: false });
      if (checkApiStatus(response)) {
        this.setState({ dataKits: response.data.data })
        trackActivity('Page Visited', {
          page_title: `${props.match.params.kitType === 'marketing_kit' ? 'Marketing ' : 'Engineering'} Data Kit`
        })
      }
    })
}
export function addFilesToMiniPackAndKitApi(props, data) {
  this.setState({ pageLoader: true });
  globalPostService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/data-room/status-change/category/`, data)
    .then(response => {
      this.setState({ pageLoader: false, selectedItems: { ids: [] } });
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        trackActivity('File Added', { page_title: props.match.params.kitType === 'marketing_kit' ? "Marketing Kit" : "Engineering Kit", to_folder: data.to_folder, uuid: data.uuid, type: data.type })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function removeDataKitFilesApi(props, data) {
  /**
    - Remove Single, Multi or All files from Marketing and Engineering Kit
  **/
  let payload = {};
  payload = {
    ...payload,
    category: props.match.params.kitType === 'marketing_kit' ? 1 : 2,
    remove_all: data.title === 'Remove All' ? true : false,
    to_folder: props.match.params.uuid,
    uuid: data.ids,
    flag: false
  }
  this.setState({ pageLoader: true });
  globalPostService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/data-room/status-change/category/`, payload)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        this.setState({ bulkOperation: { ids: [], modal: false } });
        this.getResourcesKitFilesApi(props, { tab: props.match.params.kitType }, 'pageLoader')
        trackActivity('File(s) Removed', { page_title: props.match.params.kitType === 'marketing_kit' ? "Marketing Kit" : "Engineering Kit", remove_all: data.title === 'Remove All' ? true : false, to_folder: props.match.params.uuid, uuid: data.ids, multi: data.multi })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function downloadResourceKitApi(props, queryParams) {
  this.setState({ pageLoader: true });
  if (queryParams.download === 'data_room_tpl') {
    globalExportService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/resources/`, queryParams)
      .then(response => {
        if (response.data.statusCode !== 500 && response.data?.type !== 'application/json') {
          this.setState({ pageLoader: false });
          downloadFileType(response.data, (`Data_Room_Template_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`), 'xlsx');
          this.props.enqueueSnackbar('Download of Data Room template successful.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          trackActivity('Item Clicked', {
            page_title: 'Dataroom Header',
            page_type: props.match.params.type,
            event_type: 'Download',
            event_desc: 'Downloaded Dataroom Template from Dataroom Header More action',
            asset_type: props.match.params.assetType,
            asset_slug: props.match.params.assetSlug,
            download_type: queryParams.download
          })
        } else {
          this.setState({ multiTemplate : true, pageLoader: false });
          // this.props.enqueueSnackbar('No Applied Lessor Template Found on this Asset.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
  } else {
    globalGetService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/resources/`, queryParams)
      .then(response => {
        this.setState({ pageLoader: false });
        if (checkApiStatus(response)) {
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          trackActivity('Item Clicked', {
            page_title: 'Dataroom Header',
            page_type: props.match.params.type,
            event_type: 'Download',
            event_desc: 'Downloaded Empty Folder Structure from Dataroom Header More action',
            asset_type: props.match.params.assetType,
            asset_slug: props.match.params.assetSlug,
            download_type: queryParams.download
          })
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
  }

}
// End of Resources Api


// Download Data
export function getKitsDownloadUrlsApi(props, queryParams) {
  this.setState({ pageLoader: true });
  globalGetService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/resources/`, queryParams)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}


// Action on Search Result page
export function onRenameSearchDataApi(props, data) {
  return new Promise(function (resolve, reject) {
    globalPostService(`records/v2/search-result/rename/`, data)
      .then(response => {
        resolve(response)
      })
  })
}
export function onDeleteSearchDataApi(props, data) {
  return new Promise(function (resolve, reject) {
    globalPostService(`records/v2/search-result/status-change/delete/`, data)
      .then(response => {
        resolve(response)
      }, trackActivity('File/Folder Deleted', {
        page_title: 'Global Search Results',
        file_uuid: data.file_uuid ? data.file_uuid : '',
        folder_uuid: data.folder_uuid ? data.folder_uuid : ''
      }))
  })
}
export function onChangeStatusSearchFileApi(props, data) {
  globalPostService(`records/v2/search-result/status-change/publish/`, data)
    .then(response => {
      if (checkApiStatus(response)) {

      }
    })
}
export function changeOCRPriorityDataroomApi(payload) {
  return new Promise(function (resolve, reject) {
    globalPostService('records/v2/change-ocr-priority/', payload)
      .then(response => {
        resolve(response)
      })
  })
}
