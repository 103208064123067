import React, { useEffect, useState } from 'react'
import { Link, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, CircularProgress } from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { checkApiStatus, checkPermission, getFileIcon } from '../../../utils'
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from '../../../utils/globalApiServices'
import { useSnackbar } from 'notistack'
import AddEditMiniPackSections from '../components/AddEditMiniPackSections'
import DataRoomFilesSelection from '../components/DataRoomFilesSelection'
import { Autocomplete } from '@material-ui/lab'
import { DeletePopUp, EmptyCollection, PageLoader } from '../../../shared_elements'
import { ArrowBack, ExpandMore, GetApp, Info } from '@material-ui/icons'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DropzoneArea } from 'material-ui-dropzone'
import moment from 'moment'
import axios from 'axios'
import folderIcon from '../../../assets/images/folder_icon.svg'
import { ShareContentModal } from '../components'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DataroomLayoutModal from '../../../shared_elements/components/DataroomLayoutModal'

const CertificatesHd = () => {
    return (
        <TableHead style={{ position: "sticky", top: '0' }}>
            <TableRow>
                <TableCell width='700px'>File/Folder Name</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell align='right'>Action(s)</TableCell>
            </TableRow>
        </TableHead>
    )
}
const CertificateListing = ({ item, onDelete, onMdlClick, getFileIcon, fileExtensionlist }) => {
    return (
        item?.folder_id && !item?.file_id?
        <TableRow style={{backgroundColor :item?.folder_exists ?'':'#ffeeee'  }}>
            <TableCell>
                <div style={{color:item?.folder_exists ?'':'red'  }}>
                <img src={folderIcon} width='15' style={{ marginRight: '5px' }} />
                    {item?.folder_name || '--'}
                    <div style={{ fontSize: '11px',color:item?.folder_exists ?'':'red'  }}>
                        {item?.folder_path || '--'}
                    </div>
                    { item?.folder_exists ?null :
                        <div className='info-area-mini-pack'>
                        This Folder has been modified in the Dataroom.
                    </div> 
                    }
                </div>
            </TableCell>
            <TableCell>
                <div style={{color:item?.folder_exists ?'':'red'  }}>
                    {item?.created_by?.name || '--'}
                    <div style={{ fontSize: '11px' }}>
                        {item?.created_at ? moment(item?.created_at).format('DD-MM-YYYY, hh:mm a') : null}
                    </div>
                </div>
            </TableCell>
            <TableCell>
                    <ul className='list-inline' style={{ float: 'right' }}>
                        <li className='list-inline-item' style={{ cursor: 'pointer', marginRight: '5px' }}>
                            <Tooltip arrow title='View Folder' placement='top'>
                                <OpenInNewIcon fontSize='small' color='primary' onClick={onMdlClick} />
                            </Tooltip>
                        </li>
                        {checkPermission('records', 'mini-pack', 'U') ?
                            <li className='list-inline-item' style={{ cursor: 'pointer' }}>
                                <Tooltip arrow title='Unlink Folder' placement='top'>
                                    <DeleteOutlineIcon onClick={() => onDelete()} fontSize='small' color='secondary' />
                                </Tooltip>
                            </li>
                            : null}
                </ul>
            </TableCell>
        </TableRow>:
        <TableRow style={{backgroundColor :item?.file_exists ?'':'#ffeeee'  }}>
            <TableCell>
                <div style={{color:item?.file_exists ?'':'red'  }}>
                <div style={{display:'flex'}}>
                <img  width={12} style={{ marginRight: '5px' }} src={getFileIcon(fileExtensionlist, item.extension)} alt={item.extension} />
                    {item?.file_name || '--'}
                    </div>
                    <div style={{ fontSize: '11px',color:item?.file_exists ?'':'red'  }}>
                        {item?.folder_path || '--'}
                    </div>
                    { item?.file_exists ?null :
                        <div className='info-area-mini-pack'>
                        This File or it's Folder has been modified in the Dataroom.
                    </div> 
                    }
                </div>
            </TableCell>
            <TableCell>
                <div style={{color:item?.file_exists ?'':'red'  }}>
                    {item?.created_by?.name || '--'}
                    <div style={{ fontSize: '11px' }}>
                        {item?.created_at ? moment(item?.created_at).format('DD-MM-YYYY, hh:mm a') : null}
                    </div>
                </div>
            </TableCell>
            <TableCell>
                <ul className='list-inline' style={{ float: 'right' }}>
                    <li className='list-inline-item' style={{ cursor: 'pointer', marginRight: '5px' }}>
                        <Tooltip arrow title='View File' placement='top'>
                            <VisibilityIcon color='primary' fontSize='small'
                                onClick={() => window.open(`/records/preview/${item.file_uuid}`, '_blank')}
                            />
                        </Tooltip>
                    </li>
                    {
                        checkPermission('records', 'mini-pack', 'U') ?
                            <li className='list-inline-item' style={{ cursor: 'pointer' }}>
                                <Tooltip arrow title='Unlink File' placement='top'>
                                    <DeleteOutlineIcon onClick={() => onDelete()} fontSize='small' color='secondary' />
                                </Tooltip>
                            </li>
                            : null}


                </ul>
            </TableCell>
        </TableRow>
    )
}
const InitialConfig = ({ toggleModalFn, props, getResponseBack }) => {
    const [config, setConfig] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [configOptions, setConfigOptions] = useState([])
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        getConfigOptions()
    }, [])
    const getConfigOptions = () => {
        globalGetService(`records/v2/minipack/config/`, { dropdown: true, status: 1 })
            .then(response => {
                if (checkApiStatus(response)) {
                    setConfigOptions(response.data.data?.list)
                }
                else {
                    enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            })
    }
    const saveInitialConfig = (sync) => {
        let payload = {}
        payload = {
            ...payload,
            config: config,
            apply_sync: sync =='sync'?true : false
        }
        setLoading(true)
        globalPostService(`records/v2/${props.match.params?.assetType}/${props.match.params?.assetSlug}/minipack-sections/`, payload)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    getResponseBack()
                }
            })
    }
    return (
        <>
        {
            isLoading ? <PageLoader/> : null
        }
        <Dialog
            open={true}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                Select Mini-Pack
            </DialogTitle>
            <DialogContent dividers={true}>
                <Autocomplete
                    style={{ paddingTop: "13px", marginBottom: '10px', width: '350px' }}
                    options={configOptions}
                    getOptionLabel={option => option.name}
                    value={config || null}
                    onChange={(e, data) => setConfig(data)}
                    renderInput={params => <TextField required variant='outlined' placeholder="Please select Mini-Pack" size='small' {...params} margin="none" label='Config Mini-Pack' fullWidth InputLabelProps={{ shrink: true }} />}
                />
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='primary' size='small' onClick={toggleModalFn}>
                    Cancel
                </Button>
                <Button disabled={config ? false : true} variant='contained' color='primary' style={{ margin: '15px' }} size='small' onClick={() => saveInitialConfig()}>
                    Save
                </Button>
                { checkPermission('records', 'mini-pack', 'U') ?
                    <Button disabled={config ? false : true} variant='contained' color='primary' style={{ margin: '15px 15px 15px 0px' }} size='small' onClick={() => saveInitialConfig('sync')}>
                    Save & Sync
                </Button>
                : null}
            </DialogActions>
        </Dialog>
        </>
    )
}
const UploadCoverPhoto = ({ savePhoto, toggleModalFn, miniPackDetails }) => {
    const [image, setImage]= useState(null)
    const [isLoading, setLoading]= useState(null)
    const downLoadFileAxios = (fileUrl='url', fileName='file.jpg') => {
        setLoading(true)
        axios.get(fileUrl, { responseType: 'arraybuffer', })
        .then(response => {
            setLoading(false)
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click()
        })
      }
    const downloadImage  = () => {
        downLoadFileAxios(miniPackDetails.cover_photo, miniPackDetails?.cover_photo_name ? 'Cover Photo '+miniPackDetails?.cover_photo_name : 'Cover Photo.jpeg')
      }
      const changeImg =(file)=>{
        setImage(file?.length ?file: image)
      }
    return (
        <Dialog
            open={true}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="lg"
        >
            <DialogTitle>
                Cover Photo
            </DialogTitle>
            <DialogContent dividers={true} >
                {
                    miniPackDetails?.cover_photo ?
                        <div className="cover-pic-action-box" style={{width:"430px", height:"140px"}}>
                            <ul className="list-inline flex-centered">
                                {checkPermission('records', 'mini-pack', 'DN') ?
                                    <li className="list-inline-item" style={{ marginRight: "10px" }}>
                                        <Button style={{width:'110px'}} color="primary" variant="outlined" onClick={downloadImage}>
                                         { isLoading ? <CircularProgress size={24}/> :'Download'}
                                        </Button>
                                    </li> : null
                                }
                                {checkPermission('records', 'mini-pack', 'U') ?
                                    <li className="list-inline-item" style={{ marginRight: "10px" }}>
                                        <input
                                            accept="image/*"
                                            id="contained-button-file"
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={(e) => changeImg(e.target.files)}
                                        />
                                        <label htmlFor="contained-button-file">
                                            <span color="primary" variant="outlined">CHANGE</span>
                                        </label>
                                    </li> : null
                                }
                                {checkPermission('records', 'mini-pack', 'D') || checkPermission('records', 'mini-pack', 'U') ?
                                    <li className="list-inline-item" style={{ marginRight: "10px" }}>
                                        <Button onClick={() => savePhoto(null)} color="secondary" variant="outlined">Remove</Button>
                                    </li> : null
                                }
                            </ul>
                            <img width="200" style={{ margin: '10px auto', display: 'block' }} src={image? URL.createObjectURL(image[0]):miniPackDetails.cover_photo} alt="" />
                        </div> :

                        <div className="dropzone-area" style={{ width: '300px', position: 'relative', margin: '0 auto' }}>
                            <DropzoneArea
                                filesLimit={1}
                                maxFileSize={80000000}
                                showPreviewsInDropzone={false}
                                acceptedFiles={['image/jpg', 'image/jpeg', 'image/png']}
                                showPreviews={true}
                                dropzoneText={<p>Drag & Drop files<br /> OR <br /> Click Here</p>}
                                dropzoneClass="drag-drop-cnt"
                                maxWidth="sm"
                                showAlerts={['error', 'info']}
                                alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                                onDrop={(files) => setImage(files) }
                            />
                            <p className="file-upload-note">Supported Extension .jpg, jpeg, .png</p>
                        </div>
                }
            </DialogContent>

            <DialogActions>
                <Button variant='outlined' color='primary' size='small' onClick={toggleModalFn}>
                    Cancel
                </Button>
                {/* {
                    miniPackDetails?.cover_photo ? null : */}
                <Button disabled={image ? false : true} variant='contained' color='primary' style={{ margin: '15px' }} size='small' onClick={() => savePhoto(image)}>
                    Save
                </Button>
                {/* } */}
            </DialogActions>
        </Dialog>
    )
}
export default function MiniPackDataRoomConfig(props) {
    const [addEditSection, setAddEditSection] = useState({})
    const [keywordsCreated, setKeywordsCreated] = useState([])
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [sectionData, setSectionData] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [initialConfig, setInitialConfig] = useState(false)
    const [sectionList, setSectionList] = useState({})
    const [linkModal, setLinkModal] = useState(false)
    const [deleteSectionModal, setDeleteSectionModal] = useState(false)
    const [deleteFileModal, setDeleteFileModal] = useState(false)
    const [anchorEl, setAnchorEl] = useState([]);
    const [generateOpts, setGenerateOpts] = useState(null);
    const [miniPackDetails, setMiniPackDetails] = useState(null);
    const [uploadCVPhoto, setUploadCVPhoto] = useState(null);
    const [syncOPts, setSyncOpts] = useState(null);
    const [shareContent, setShareContent] = useState({modal:false});
    const [openDRModal, setOpenDRModal] = useState({modal:false});
    const [fileExtensionlist, setFileExtensionlist] = useState([])
    useEffect(() => {
        getSectionListFn()
        miniPackDetailsFn()
        getFileExtensionTypes()
    }, [])

    const miniPackDetailsFn = () => {
        globalGetService(`records/v2/${props.match.params?.assetType}/${props.match.params?.assetSlug}/minipack-details/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setMiniPackDetails(response.data.data)
                }
            })
    }
    const uploadCoverPhoto = (files) => {
        let formData = new FormData()
        formData.append('cover_photo',files? files[0]:null);
        setLoading(true)
        globalPostService(`records/v2/${props.match.params?.assetType}/${props.match.params?.assetSlug}/minipack-details/`, formData)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    setUploadCVPhoto(false)
                    miniPackDetailsFn()
                    enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
                else {
                    enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            })
    }
    const getSectionListFn = () => {
        setLoading(true)
        globalGetService(`records/v2/${props.match.params?.assetType}/${props.match.params?.assetSlug}/minipack-config/${props.match.params?.configId}/minipack-section/`)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    setSectionList(response.data.data)
                }
                else {
                    enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            })
    }
    const saveSectionData = (data, sync) => {
        let validateInput = {}
        validateInput = {
            ...validateInput,
            name: sectionData?.name ? '' : 'Please enter Name',
            keywords: sectionData?.keywords?.length ? '' : 'Please enter Keywords',
        }
        if (Object.keys(validateInput).every(item => { return validateInput[item] === '' })) {
            let payload = {}
            payload = {
                ...payload,
                config: null,
                apply_sync: sync =='sync'?true : false,
                section: {
                    ...sectionData,
                    major_assembly_asset_type: sectionData?.major_assembly_asset_type || null,
                    major_assembly_asset: sectionData?.major_assembly_asset || null,
                    description: sectionData?.description || null
                }
            }
            setLoading(true)
            if (payload?.section?.id) {
                globalPutService(`records/v2/${props.match.params?.assetType}/${props.match.params?.assetSlug}/minipack-config/${props.match.params?.configId}/minipack-section/${payload?.section?.id}/`, payload)
                    .then(response => {
                        setKeywordsCreated([])
                        setLoading(false)
                        if (checkApiStatus(response)) {
                            setSectionData(false)
                            setAddEditSection(false)
                            getSectionListFn()
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                        else {
                            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    })
            }
            else {
                globalPostService(`records/v2/${props.match.params?.assetType}/${props.match.params?.assetSlug}/minipack-config/${props.match.params?.configId}/minipack-section/`, payload)
                    .then(response => {
                        setKeywordsCreated([])
                        setLoading(false)
                        if (checkApiStatus(response)) {
                            setSectionData(false)
                            setAddEditSection(false)
                            getSectionListFn()
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                        else {
                            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    })
            }
        }
        else {
            setError(validateInput)
        }
    }
    const linkSectionFiles = (section) => {
        setLinkModal({ modal: true, data: section })
    }
    const syncMiniPackFn = (query) => {
        setLoading(true)
        globalGetService(`records/v2/${props.match.params?.assetType}/${props.match.params?.assetSlug}/minipack-config/${props.match.params?.configId}/sync-minipack-sections-files/`, query)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    getSectionListFn()
                    miniPackDetailsFn()
                    enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })

                }
                else {
                    enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            })
    }
    const downloadMiniPackFn = (data, query) => {
        setLoading(true)
        if (!data?.id) {
            globalGetService(`records/v2/${props.match.params?.assetType}/${props.match.params?.assetSlug}/minipack-config/${props.match.params?.configId}/minipack-section/`, query)
                .then(response => {
                    setLoading(false)
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                    else {
                        enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                })
        } else {
            globalGetService(`records/v2/${props.match.params?.assetType}/${props.match.params?.assetSlug}/minipack-config/${props.match.params?.configId}/minipack-section/${data?.id}/`, query)
                .then(response => {
                    setLoading(false)
                    if (checkApiStatus(response)) {
                        getSectionListFn()
                        enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })

                    }
                    else {
                        enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                })
        }
    }
    const deleteAlertFn = () => {
        setLoading(true)
        globalDeleteService(`records/v2/${props.match.params?.assetType}/${props.match.params?.assetSlug}/minipack-config/${props.match.params?.configId}/minipack-section/${deleteSectionModal?.data?.id}/`)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    getSectionListFn()
                    setDeleteSectionModal(false)
                }
            }
            )
    }
    const onDeleteFile = () => {
        let payload= {}
        setLoading(true)
        globalPostService(`records/v2/${props.match.params?.assetType}/${props.match.params?.assetSlug}/minipack-config/${props.match.params?.configId}/minipack-section/${deleteFileModal?.data?.section?.id}/unlink-files-folders/`, deleteFileModal?.data?.item?.file_id ? { files: [deleteFileModal?.data?.item]}:{ folders: [deleteFileModal?.data?.item]} )
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    getSectionListFn()
                    setDeleteFileModal(false)
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            }
            )
    }
    const handleClick = (event, index) => {
        const newAnchorEls = [...anchorEl];
        newAnchorEls[index] = event.currentTarget;
        setAnchorEl(newAnchorEls);
    };
    const handleClose = (index) => {
        const newAnchorEls = [...anchorEl];
        newAnchorEls[index] = null;
        setAnchorEl(newAnchorEls);
    };
    const getFileExtensionTypes = () => {
        globalPostService(`console/get-constants/`, { constant_types: ["file_extension_drive"] })
            .then(response => {
                if (checkApiStatus(response)) {
                    setFileExtensionlist(response.data.data)
                }
            })
    }
    const shareContentFn =(flag, data)=>{
        setLoading(true)
        let payload ={hour:data, share:flag, type:'minipack', id:props.match.params?.configId }
        globalPostService(`records/v2/${props.match.params.assetType}/${props.match.params.assetSlug}/data-room/share/`, payload)
        .then(response =>{
            setLoading(false)
            if(checkApiStatus(response)){
                setShareContent(prevState=>({
                    ...prevState,
                     data:{
                        ...prevState.data,
                        share_enabled: flag?true: false,
                        share_key: response.data.data.share_key ? response.data.data.share_key : '',
                        valid_till: response.data.data.valid_till
                     }

                }))
            }
            else{
                enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
            }
        })
    }
    return (
        <>
            {isLoading ? <PageLoader /> : null}
            {
                    // <Paper style={{ height: `${window.innerHeight - 85}px` }}>
                    //     <div style={{ textAlign: 'center', position: 'relative', top: "270px" }}>
                    //         Mini-Pack is not config yet, click on Config Mini-Pack to config mini-pack.
                    //         <div>
                    //             <Button disabled={checkPermission('records', 'mini-pack', 'C') ? false : true} variant='contained' color='primary' style={{ margin: '15px' }} size='small' onClick={() => { setInitialConfig({ modal: true }) }}>
                    //                 Config Mini-Pack
                    //             </Button>
                    //         </div>
                    //     </div>
                    //     {
                    //         initialConfig?.modal ?
                    //             <InitialConfig
                    //                 props={props}
                    //                 toggleModalFn={() => setInitialConfig(false)}
                    //                 getResponseBack={() => { setInitialConfig(false); getSectionListFn() }}
                    //             /> : null
                    //     }
                    // </Paper>
                    <>
                        <span style={{ color: '#4050b5', cursor: 'pointer', position: 'relative', bottom: '10px', display: 'inline-flex' }} onClick={() => props.history.push(`/${props.match.params?.assetType}/${props.match.params?.assetSlug}/data-room/mini-pack`)}>
                            <ArrowBack />
                            <span style={{ marginLeft: '3px' }}> {'Back to Listing'}</span>
                        </span>
                    <Paper className='mini-pack-config-dtrm' style={{ minHeight: `${window.innerHeight - 100}px` }}>
                        <Grid container spacing={1}>
                            {/* {
                                sectionList?.list?.length ? */}
                                    <>
                                        <Grid item xs='3'>
                                            {
                                                checkPermission('records', 'mini-pack', 'U') ?
                                                    <div>
                                                        <Button variant='outlined' color='primary' style={{ margin: '10px 5px 5px 5px' }} size='small' onClick={(e) => { setSyncOpts(e.currentTarget) }}>
                                                            Sync Mini-Pack <ExpandMore />
                                                        </Button>
                                                        <Menu
                                                            id={'generate-sync-ops'}
                                                            style={{ top: "44px" }}
                                                            anchorEl={syncOPts}
                                                            keepMounted
                                                            open={Boolean(syncOPts)}
                                                            onClose={() => setSyncOpts(null)}
                                                        >
                                                            <MenuItem onClick={() => { syncMiniPackFn({ remove_linked_file: true }); setSyncOpts(null) }}>
                                                                Sync and remove Linked Files
                                                                <div>
                                                                    <Tooltip title='Manually Linked / Added Files may be Removed.' arrow >
                                                                        <span>&nbsp;  <Info style={{ fontSize: "14px", position:'relative', top:'3px' }} /></span>
                                                                    </Tooltip>
                                                                </div>
                                                            </MenuItem>
                                                            <MenuItem onClick={() => { syncMiniPackFn(null); setSyncOpts(null) }}>
                                                                Sync and keep Linked Files
                                                                <div>
                                                                    <Tooltip title='Manually Linked / Added Files won’t be Removed.' arrow >
                                                                        <span>&nbsp;  <Info style={{ fontSize: "14px", position:'relative', top:'3px' }} /></span>
                                                                    </Tooltip>
                                                                </div>
                                                            </MenuItem>
                                                        </Menu>
                                                        <div style={{ fontSize: "12px", marginLeft: "13px" }}>Last Sync at : &nbsp;
                                                            {miniPackDetails?.last_sync_at ? moment(miniPackDetails?.last_sync_at).format('DD-MM-YYYY, hh:mm a') : '--'}
                                                        </div>
                                                            <div style={{ fontSize: "12px", marginLeft: "13px" }}>Last Sync by : &nbsp;
                                                                {miniPackDetails?.last_sync_by?.name || ' --'}
                                                            </div>
                                                    </div>
                                                    : null}
                                        </Grid>
                                        <Grid item xs='9'>
                                            <div style={{ float: 'right' }}>
                                                <Button variant='contained' color='primary' style={{ margin: '10px 5px 5px 5px' }} size='small' onClick={()=>setShareContent({modal:true, data:{name:'Mini-Pack', valid_till:miniPackDetails?.valid_till, share_key:miniPackDetails?.share_key, share_enabled:miniPackDetails?.share_enabled}})}>
                                                    Share
                                                </Button>
                                                {
                                                    checkPermission('records', 'mini-pack', 'C') ?
                                                        <Button variant='contained' color='primary' style={{ margin: '10px 5px 5px 5px' }} size='small' onClick={() => { setError({}); setAddEditSection({ modal: true }) }}>
                                                            Add Section
                                                        </Button> : null}
                                                {
                                                    checkPermission('records', 'mini-pack', 'DN') ?
                                                        <Button variant='contained' color='primary' style={{ margin: '10px 5px 5px 5px' }} size='small' onClick={() => { downloadMiniPackFn({}, { download: true }) }}>
                                                            Download Mini-Pack
                                                        </Button>
                                                        : null}
                                                {
                                                    checkPermission('records', 'mini-pack', 'DN') ?
                                                        <>
                                                            <Button variant='outlined' color='primary' style={{ margin: '10px 5px 5px 5px' }} size='small' aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => setGenerateOpts(e.currentTarget)}>
                                                                Generate Mini-Pack <ExpandMore />
                                                            </Button>
                                                            <Menu
                                                                id={'generate-ops'}
                                                                style={{ top: "44px" }}
                                                                anchorEl={generateOpts}
                                                                keepMounted
                                                                open={Boolean(generateOpts)}
                                                                onClose={() => setGenerateOpts(null)}
                                                            >
                                                                <MenuItem onClick={() => { setGenerateOpts(null); downloadMiniPackFn({}, { generate: true }) }}>
                                                                    <PictureAsPdfIcon /> PDF
                                                                </MenuItem>
                                                            </Menu>
                                                        </>
                                                        : null}
                                                {miniPackDetails?.cover_photo ?

                                                    <Button  variant='contained' color='primary' style={{ margin: '10px 5px 5px 5px' }} size='small' onClick={() => { setUploadCVPhoto({ modal: true }) }}>
                                                        {!checkPermission('records', 'mini-pack', 'U') ? 'View Cover Photo' :
                                                            'View or change Cover Photo'
                                                        }
                                                    </Button> :
                                                    <Button disabled={checkPermission('records', 'mini-pack', 'U') ? false : true} variant='contained' color='primary' style={{ margin: '10px 5px 5px 5px' }} size='small' onClick={() => { setUploadCVPhoto({ modal: true }) }}>
                                                        Upload Cover photo
                                                    </Button>
                                                }
                                            </div>
                                        </Grid>
                                    </>
                                    {/* : null} */}
                        </Grid>
                        {
                            sectionList?.list?.map((section, index) =>
                                <Grid style={{ paddingBottom: sectionList?.list?.length - 1 == index ? '100px' : '0px', }} item xs='12'>
                                    <Paper style={{ borderTop: '1px solid #bababa', borderRadius: "0px", marginTop: "20px" }}>
                                        <Grid container spacing={1}>
                                            <Grid item xs='3'>
                                                <div className='table-hd' style={{ margin: '9px' }}>
                                                    {section?.name || '--'}
                                                </div>
                                            </Grid>
                                            <Grid item xs='9'>
                                                <div>
                                                    {
                                                        checkPermission('records', 'mini-pack', 'U') || checkPermission('records', 'mini-pack', 'DN') || checkPermission('records', 'mini-pack', 'D') ?
                                                        <Button variant='outlined' color='primary' style={{ float: 'right', margin: '5px' }} size='small' aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleClick(e, index)}>
                                                        Actions <ExpandMore />
                                                    </Button>
                                                    : null}
                                                    <Menu

                                                        id={'mini-pack-pop-up' + index}
                                                        // style={{ top: "44px" }}
                                                        anchorEl={anchorEl[index]}
                                                        keepMounted
                                                        open={Boolean(anchorEl[index])}
                                                        onClose={() => handleClose(index)}
                                                    >
                                                        {checkPermission('records', 'mini-pack', 'U') ?
                                                            <MenuItem onClick={() => { handleClose(index); linkSectionFiles(section) }}>
                                                                Link file/folder from Dataroom
                                                            </MenuItem>
                                                            : null}
                                                        {checkPermission('records', 'mini-pack', 'U') ?
                                                            <MenuItem onClick={() => { handleClose(index); setError({}); setSectionData(section); setKeywordsCreated(section?.keywords); setAddEditSection({ modal: true, data: section }) }}>
                                                                Edit Section
                                                            </MenuItem>
                                                            : null}
                                                        {checkPermission('records', 'mini-pack', 'DN') ?
                                                            <MenuItem onClick={() => { handleClose(index); downloadMiniPackFn(section, { download: true }) }}>
                                                                Download Section Files
                                                            </MenuItem>
                                                            : null}
                                                        {checkPermission('records', 'mini-pack', 'DN') ?
                                                            <MenuItem onClick={() => { handleClose(index); downloadMiniPackFn(section, { generate: true }) }}>
                                                                Generate Section Minipack
                                                            </MenuItem>
                                                            : null}
                                                        {checkPermission('records', 'mini-pack', 'D') ?
                                                            <MenuItem onClick={() => { handleClose(index); setDeleteSectionModal({ modal: true, data: section }) }}>
                                                                Delete Section
                                                            </MenuItem>
                                                            : null}
                                                    </Menu>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Paper style={{ borderRadius: '0px' }}>
                                            <Table className='mui-table-format'>
                                                <CertificatesHd />
                                                <TableBody>
                                                    {
                                                        section?.folders?.length || section?.files?.length ? [...section?.folders, ...section?.files].map(item => (
                                                            <CertificateListing
                                                                fileExtensionlist={fileExtensionlist}
                                                                getFileIcon={getFileIcon}
                                                                item={item}
                                                                onDelete={() => setDeleteFileModal({ modal: true, data: { section: section, item: item } })}
                                                                onMdlClick={()=>setOpenDRModal({modal:true, uuid:item?.folder_uuid})}
                                                            />
                                                        )) :
                                                            null
                                                    }
                                                </TableBody>
                                            </Table>
                                            {section?.files?.length || section?.folders?.length ? null :
                                                <Paper style={{ padding: '20px', textAlign: "center" }}>No Records Found </Paper>}
                                        </Paper>
                                    </Paper>
                                </Grid>
                            )
                        }
                        {
                            sectionList?.list?.length ? null : isLoading ? null :
                            <EmptyCollection title='No Records Found' />
                        }
                        {
                            addEditSection?.modal ?
                                <AddEditMiniPackSections
                                    props={props}
                                    setKeywordsCreated={setKeywordsCreated}
                                    setError={setError}
                                    keywordsCreated={keywordsCreated}
                                    error={error}
                                    sectionData={sectionData}
                                    saveSectionData={saveSectionData}
                                    setSectionData={setSectionData}
                                    setAddEditSection={setAddEditSection}
                                    addEditSection={addEditSection}
                                    toggleModal={() => { setAddEditSection(false); setKeywordsCreated([]); setSectionData({}); }}
                                />
                                : null
                        }
                        {
                            linkModal?.modal ?
                                <DataRoomFilesSelection
                                    toggleModalFn={() => setLinkModal(false)}
                                    props={props}
                                    sectionData={linkModal?.data}
                                />
                                : null
                        }
                        {
                            deleteSectionModal?.modal ?
                                <DeletePopUp
                                    modal={deleteSectionModal?.modal}
                                    toggleModalFn={() => setDeleteSectionModal({ modal: false, data: null })}
                                    title="Delete Section"
                                    content={<h4>Are you sure you want to delete?</h4>}
                                    triggerEventFn={() => deleteAlertFn(deleteSectionModal?.data)}
                                /> : null
                        }
                        {
                            deleteFileModal?.modal ?
                                <DeletePopUp
                                    modal={deleteFileModal?.modal}
                                    toggleModalFn={() => setDeleteFileModal({ modal: false, data: null })}
                                    title="Unlink File/Folder"
                                    confirmText='Unlink'
                                    content={<h4>Are you sure you want to unlink this file/folder?</h4>}
                                    triggerEventFn={() => onDeleteFile(deleteFileModal?.data)}
                                /> : null
                        }
                        {
                            uploadCVPhoto?.modal ?
                                <UploadCoverPhoto
                                    miniPackDetails={miniPackDetails}
                                    savePhoto={uploadCoverPhoto}
                                    toggleModalFn={() => setUploadCVPhoto(null)}
                                />
                                : null
                        }
                        {
                            shareContent?.modal ?
                                <ShareContentModal
                                    isRootNavigation={false}
                                    shareContent={shareContent}
                                    toggleModalFn={() => {miniPackDetailsFn(); setShareContent({ modal: false, data: {} })}}
                                    sharetheDmContent={(flag, hour) => shareContentFn(flag, hour)}
                                /> : null
                        }
                        {
                            openDRModal?.modal ?
                            <DataroomLayoutModal
                            props={props}
                            uuid={openDRModal?.uuid}
                            toggleModalFn={()=>setOpenDRModal({modal:false})}
                            />
                            :null   
                        }
                    </Paper>
                    </>
            }
        </>
    )
}
