import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField, ListItem, ListItemIcon, ListItemText, Checkbox, Paper, Breadcrumbs, Link, Typography, Tooltip, Button, CircularProgress, Chip } from '@material-ui/core';
import folderIcon from '../../assets/images/folder_icon.svg';
import { useSnackbar } from 'notistack';
import { checkApiStatus, getFileIcon } from '../../utils';
import { globalGetService, globalPostService } from '../../utils/globalApiServices';
import DeleteIcon from '@material-ui/icons/Delete';
import PageLoader from './PageLoader';
import { assetTypeValues } from '../../constants';

export default function DataroomLayoutModal({ toggleModalFn, props, uuid, assetData, type, openDRModal}) {
    const [dataRoom, setDataRoom] = useState({})
    const [navLength, setNavLength] = useState(0)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [fileExtensionlist, setFileExtensionlist] = useState([]);
    useEffect(() => {
        getSourceFolders(uuid, 'initalLoad')
        getFileExtensionTypes(uuid)
    }, [])
    const getSourceFolders = (uuid, initalLoad) => {
        setLoading(true)
        let url = type=='shared'?`records/v2/view-shared-records/${openDRModal?.uuid}/?folder_uuid=${uuid}`: `records/v2/${assetData?.asset_type ? assetTypeValues[assetData?.asset_type]?.urlType :props.match.params?.assetType}/${assetData ? assetData?.slug :props.match.params?.assetSlug}/data-room/${uuid}/?navigation=true&tree_structure=true&sort=name&sort_by=asc`
        globalGetService(url)
        .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    setDataRoom({
                        ...response.data.data,
                        list: response.data.data.list.map(listItem => ({
                            ...listItem,
                            parent: response.data.data.navigation[response.data.data.navigation.length - 1]
                        }))
                    });
                    if(initalLoad){setNavLength(response.data.data.navigation.length)}
                }
                else{
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
            })
    }
    const getFileExtensionTypes = () => {
        globalPostService(`console/get-constants/`, { constant_types: ["file_extension_drive"] })
            .then(response => {
                if (checkApiStatus(response)) {
                    setFileExtensionlist(response.data.data)
                }
            })
    }
    return (
        <Dialog
            open={true}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                Dataroom Layout
            </DialogTitle>
            <DialogContent dividers={true} style={{ minHeight: "400px", width: '800px' }}>
                { isLoading? <PageLoader /> : null}
                <div style={{ height: '550px', overflow: 'scroll', border: '1px solid #e1e1e1', marginTop: '5px' }}>
                    <div style={{ position: 'sticky', padding: '5px', top: "0", background: '#fff', zIndex: '5', borderBottom: '1px solid #e1e1e1', display: 'flex', alignItems: 'center' }} >
                        <div style={{ paddingLeft: '30px' }}>
                            <Breadcrumbs maxItems={3} aria-label="breadcrumb" >
                                {dataRoom?.navigation?.length ?
                                    dataRoom.navigation.map((nav, index) =>
                                        dataRoom.navigation.length - 1 === index || (navLength - 1 > index) ? <Typography>{nav.name}</Typography> :
                                            <Link style={{ cursor: 'pointer' }} className='parent-fldr-lnk' onClick={() => getSourceFolders(nav.uuid)}>{nav.name}</Link>
                                    ) : null
                                }
                            </Breadcrumbs>
                        </div>
                    </div>
                    {(() => {
                        return (
                            <div className='folder-list'>
                                {dataRoom?.list?.length > 0 && dataRoom?.list.map((item) =>
                                    <ListItem key={item.id} dense button={true}>
                                        <ListItemIcon>
                                            {
                                                item?.type == 'file' ?
                                                    <img style={{ width: item.type === 'folder' ? '26px' : '20px' }} className="folder-img" src={getFileIcon(fileExtensionlist, item.extension)} alt={item.extension} />
                                                    :
                                                    <img style={{ width: item.type === 'folder' ? '26px' : '20px' }} className="folder-img" src={item.type === 'folder' ? folderIcon : getFileIcon(fileExtensionlist, item.extension)} alt={item.extension} />
                                            }
                                        </ListItemIcon>
                                        <ListItemText onClick={() => item?.type == 'file' ? window.open(`/records/preview/${item.uuid}`, '_blank') : getSourceFolders(item.uuid)} id={item.id} primary={item.name} />
                                    </ListItem>
                                )}
                                {!dataRoom?.list?.length && !isLoading ? <p className='no-folders-cls' style={{ textAlign: 'center', padding: '10px' }}>No more folders</p> : null}
                            </div>
                        )
                    })()}
                </div>
                {/* </Grid> */}
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='primary' style={{ margin: '10px 5px 5px 5px' }} size='small' onClick={toggleModalFn}>
                    Okay</Button>
            </DialogActions>
        </Dialog>
    )
}
