import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField, ListItem, ListItemIcon, ListItemText, Checkbox, Paper, Breadcrumbs, Link, Typography, Tooltip, Button, CircularProgress, Chip } from '@material-ui/core';
import { globalGetService, globalPostService } from '../../../utils/globalApiServices';
import { checkApiStatus, getFileIcon } from '../../../utils';
import folderIcon from '../../../assets/images/folder_icon.svg';
import { PageLoader } from '../../../shared_elements';
import { useSnackbar } from 'notistack';

export default function DataRoomFilesSelection({ props, toggleModalFn, sectionData }) {
    const [sourceDataRoom, setSourceDataRoom] = useState({})
    const [isLoading, setLoading] = useState(false);
    const [selectedSource, setSelectedSource] = useState([]);
    const [selectedUuids, setSelectedUUids] = useState([]);
    const [selectedFoldersUuids, setSelectedFoldersUUids] = useState([]);
    const [uuidsArr, setUuidsArr] = useState([]);
    const [fileExtensionlist, setFileExtensionlist] = useState([]);
    const [recordsDetails, setRecordsDetails] = useState({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    useEffect(() => {
        getRecordsDetailsFn()
        getFileExtensionTypes()
    }, [])
    const onBulkSourceSelect = (flag) => {
        setSelectedSource([...selectedSource, { folders: sourceDataRoom?.list }]);
    }
    const getRecordsDetailsFn = () => {
        setLoading(true)
        globalGetService(`console/${props.match.params?.assetType}/${props.match.params?.assetSlug}/records-detail/`)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    setRecordsDetails(response.data.data?.record_folders)
                    getSourceFolders(response.data.data?.record_folders?.technical_records?.uuid)
                }
            })
    }
    const getFileExtensionTypes = () => {
        globalPostService(`console/get-constants/`, { constant_types: ["file_extension_drive"] })
            .then(response => {
                if (checkApiStatus(response)) {
                    setFileExtensionlist(response.data.data)
                }
            })
    }
    const getSourceFolders = (uuid) => {
        setLoading(true)
        globalGetService(`records/v2/${props.match.params?.assetType}/${props.match.params?.assetSlug}/data-room/${uuid}/?navigation=true&tree_structure=true&sort=name&sort_by=asc`,)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    setSourceDataRoom({
                        ...response.data.data,
                        list: response.data.data.list.map(listItem => ({
                            ...listItem,
                            parent: response.data.data.navigation[response.data.data.navigation.length - 1]
                        }))
                    });
                }
            })
    }
    const onSelectItem = (value, flag, type) => {
        if (flag) {
            if(type == 'file'){
                if(value?.status == 'Published'){
                    setSelectedUUids([...selectedUuids, value])
                    setUuidsArr([...uuidsArr, value.uuid])
                }
                else{
                    enqueueSnackbar('This file is not Published yet.', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            }
            else{
                if(value?.size > 209715200 ){
                    enqueueSnackbar('Folder size shall not be greater than 200MB.', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
                else if(value?.file_count >25){
                    enqueueSnackbar('Files count shall not be more than 25.', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
                else{
                    setSelectedFoldersUUids([...selectedFoldersUuids, value])
                    setUuidsArr([...uuidsArr, value.uuid])
                }
            }
        }
        else {
            setUuidsArr(uuidsArr.filter(item => item != value?.uuid))
            if(type == 'file'){
            setSelectedUUids(selectedUuids.filter(item => item?.uuid != value?.uuid))
        }
        else{
            setSelectedFoldersUUids(selectedFoldersUuids.filter(item => item?.uuid != value?.uuid))
                }
        }
    }
    const linkFile = () => {
        let payload = {}
        payload = {
            ...payload,
            files: selectedUuids,
            folders:selectedFoldersUuids
        }
        setLoading(true)
        globalPostService(`records/v2/${props.match.params?.assetType}/${props.match.params?.assetSlug}/minipack-config/${props.match.params?.configId}/minipack-sections/${sectionData?.id}/link-files-folders/`, payload)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    window.location.reload()
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            })
    }
    return (
        <>
            {
                isLoading ? <PageLoader /> : null
            }
            <Dialog
                open={true}
                onClose={toggleModalFn}
                className='console-management-modal'
                maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title">
                    Select Files/Folders
                </DialogTitle>
                <DialogContent dividers={true} style={{ minHeight:"400px", width: '800px' }}>
                    {sourceDataRoom?.list ?
                        <Grid container>
                            <Grid item sm='7'>
                                <div style={{ height: '550px', overflow: 'scroll', border: '1px solid #e1e1e1', marginTop: '5px' }}>
                                    <div style={{ position: 'sticky', padding: '5px', top: "0", background: '#fff', zIndex: '5', borderBottom: '1px solid #e1e1e1', display: 'flex', alignItems: 'center' }} >
                                        <div style={{ paddingLeft: '30px' }}>
                                            <Breadcrumbs maxItems={3} aria-label="breadcrumb" >
                                                {sourceDataRoom?.navigation?.length ?
                                                    sourceDataRoom.navigation.map((nav, index) =>
                                                        sourceDataRoom.navigation.length - 1 === index ? <Typography>{nav.name}</Typography> :
                                                            <Link style={{ cursor: 'pointer' }} className='parent-fldr-lnk' onClick={() => getSourceFolders(nav.uuid)}>{nav.name}</Link>
                                                    ) : null
                                                }
                                            </Breadcrumbs>
                                        </div>
                                    </div>
                                    {(() => {
                                        return (
                                            <div className='folder-list'>
                                                {sourceDataRoom?.list?.length > 0 && sourceDataRoom?.list.map((item) =>
                                                    <ListItem key={item.id} dense button={item?.type == 'file' ? false : true}>
                                                        <ListItemIcon>
                                                                        <Checkbox
                                                                            color='primary'
                                                                            size='small'
                                                                            edge="start"
                                                                            tabIndex={-1}
                                                                            checked={uuidsArr.includes(item?.uuid)}
                                                                            disableRipple
                                                                            onChange={(e) => onSelectItem(item, e.target.checked, item?.type)}
                                                                            inputProps={{ 'aria-labelledby': item.id }}
                                                                        />
                                                            {
                                                                item?.type == 'file' ?
                                                                    <img style={{ width: item.type === 'folder' ? '26px' : '20px' }} className="folder-img" src={getFileIcon(fileExtensionlist, item.extension)} alt={item.extension} />
                                                                    :
                                                                    <img style={{ width: item.type === 'folder' ? '26px' : '20px' }} className="folder-img" src={item.type === 'folder' ? folderIcon : getFileIcon(fileExtensionlist, item.extension)} alt={item.extension} />
                                                            }
                                                        </ListItemIcon>
                                                        <ListItemText onClick={() => item?.type == 'file' ? null : getSourceFolders(item.uuid)} id={item.id} primary={item.name} />
                                                    </ListItem>
                                                    // <DataRoomList selectedUuids={selectedUuids} item={item} onSelectItem={(flag) => onSelectSource(sourceAsset, item, flag)} onChangeNav={() => getSourceFolders(item.uuid, sourceAsset)} />
                                                )}
                                                {!sourceDataRoom?.list?.length ? <p className='no-folders-cls' style={{ textAlign: 'center', padding: '10px' }}>No more folders</p> : null}
                                            </div>
                                        )
                                    })()}
                                </div>
                            </Grid>
                            <Grid item sm='5'>
                                <div style={{ height: '550px', overflow: 'scroll', border: '1px solid #e1e1e1', marginTop: '5px' }}>
                                    <div style={{ position: 'sticky', padding: '7px', top: "0", background: '#fff', zIndex: '5', borderBottom: '1px solid #e1e1e1', display: 'flex', alignItems: 'center' }} >
                                        Files/Folders Selected
                                    </div>
                                    <div>
                                        {
                                            selectedUuids?.length || selectedFoldersUuids?.length ? [...selectedFoldersUuids,...selectedUuids]?.map((item, index) =>
                                                <div style={{ display: 'flex', marginTop: "5px" }}>
                                                    <div style={{marginLeft:"5px"}} >
                                                        {index + 1}. &nbsp;
                                                    </div>
                                                    <Tooltip title={item?.name} arrow placement='top'>
                                                        <Chip size='small'
                                                            variant='outlined'
                                                            className='chp-cls'
                                                            color={'primary'}
                                                            label={item?.name} style={{ maxWidth: '300px' }} />
                                                    </Tooltip>
                                                </div>
                                            )
                                                :
                                                <div>
                                                    <Paper style={{ textAlign: 'center', padding: '10px' }}>
                                                        No Files/Folders Selected
                                                    </Paper>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        : null
                    }
                </DialogContent>
                <DialogActions>
                    <Button size='small' variant='outlined' color="primary" onClick={() => toggleModalFn()}>Cancel</Button>
                    <Button size='small' onClick={() => linkFile()} variant="contained" color="primary">Save</Button>

                </DialogActions>
            </Dialog>
        </>
    )
}
