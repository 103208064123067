import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Link, withRouter } from 'react-router-dom';
import { getRecordsReposAc } from '../actionCreators';
import CommentIcon from '@material-ui/icons/Comment';
import ReportIcon from '@material-ui/icons/Report';
import ListAltIcon from '@material-ui/icons/ListAlt';
import folderIcon from '../../assets/images/folder_icon.svg'
import { checkPermission, getLocalStorageInfo } from '../../utils';
import { BocInstance, castleLakeInstance, titanAviInstance } from '.';
import { Divider, Hidden, Tooltip, IconButton, Grid } from '@material-ui/core';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { assetTypeValues, revAssetTypeValues } from '../../constants';
class SecondarySideNav extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentDidMount() {
    if (this.props.match.path.includes('data-room') || this.props.match.path.includes('auto-organiser') || this.props.match.path.includes('issues')) {
      this.props.getRecordsRepos();
    }
  }
  redirection = (url, uuid) => {
    const { match, location } = this.props;
    if (uuid === match.params.uuid && location.search === "?archived-assets") {
      this.props.history.push({
        pathname: this.props.match.url,
        search: `${location.search}`
      });
    }
    else {
      if (uuid !== match.params.uuid) {
        this.props.history.push({
          pathname: url,
          search: `${location.search}`
        });
      }
    }
    window.location.reload();
  }
  render() {
    const { match, recordsRepos, toggleDrawer, assetinfo } = this.props;
    const avolonInstance = getLocalStorageInfo()?.defaultLessor?.id === 470
    return (
      <Fragment>
        {match.path.includes('data-room') || match.path.includes('auto-organiser') || match.path.includes('issues') ?
          <Fragment>
            {
              avolonInstance ?
                <ul className="list-unstyled secondary-sidenav">
                  {recordsRepos?.record_folders.length ? recordsRepos?.record_folders.map((item, index) =>
                    <li onClick={() => this.redirection(`/${match.params.assetType}/${match.params.assetSlug}/data-room/${item?.key_word}/${item?.uuid}`, item.uuid)}>
                      <Link className={`flex-centered ${match.params.type === item?.key_word ? 'active' : ''}`}>
                        <img className="folder-icon" width="20" src={folderIcon} alt="Folder Icon" /> {item?.name} ({item.count})
                      </Link>
                    </li>)

                    : null
                  }
                  <li onClick={() => this.redirection(`/${match.params.assetType}/${match.params.assetSlug}/data-room/records_comments`, '')}>
                    <Link className={`flex-centered ${match.params.type === 'records_comments' ? 'active' : ''}`}>
                      {/* <img className="folder-icon" width="20" src={folderIcon} alt="Folder Icon"/>  */}

                      <CommentIcon size="small" style={{ verticalAlign: 'middle', color: "#75d1f8" }} /> Comments
                    </Link>
                  </li>
                </ul>
                :
               <div className="secondary-sidenav">
                <ul className="list-unstyled " style={{marginBottom: '90px'}}>
                  <Hidden only={['lg', 'xl']}>
                    <div className='flex-centered'>
                      <li className='list-inline-item'style={{marginBottom:'0px'}} >
                        <a style={{width:'198px'}}>{assetTypeValues[assetinfo?.assetinfo?.asset_type||assetinfo?.asset_type]?.label||''} {assetinfo?.assetinfo?.asset_name||assetinfo?.asset_name||''}</a>
                      </li>
                      <li className='list-inline-item' style={{ textAlign: 'right',marginBottom:'0px' }}>
                        <Tooltip title='Close' placement='top'>
                          <IconButton style={{ color: 'white', alignContent: 'end', cursor: 'pointer' }} onClick={toggleDrawer} >
                            <CancelRoundedIcon />
                          </IconButton>
                        </Tooltip>
                      </li >
                    </div>
                    <Divider flexItem={false} variant='fullwidth' />
                  </Hidden>
                  {recordsRepos && recordsRepos.record_folders && recordsRepos.record_folders.technical_records ?
                    <li onClick={() => this.redirection(`/${match.params.assetType}/${match.params.assetSlug}/data-room/technical/${recordsRepos.record_folders.technical_records.uuid}`, recordsRepos.record_folders.technical_records.uuid)}>
                      <Link className={`flex-centered ${match.params.type === 'technical' ? 'active' : ''}`}>
                        <img className="folder-icon" width="20" src={folderIcon} alt="Folder Icon" /> Technical
                        <span style={{ float: "right" }} className={recordsRepos.record_folders.technical_records.count >= 1000 ? 'folder-count-blk' : 'folder-count-sm'}>({recordsRepos.record_folders.technical_records.count})</span>
                      </Link>
                    </li> : null
                  }

                  {recordsRepos && recordsRepos.record_folders && recordsRepos.record_folders.inspection_records && !recordsRepos.record_folders.inspection_records.disable_inspections ?
                    <li onClick={() => this.redirection(`/${match.params.assetType}/${match.params.assetSlug}/data-room/inspection/${recordsRepos.record_folders.inspection_records.uuid}`, recordsRepos.record_folders.inspection_records.uuid)}>
                      <Link className={`flex-centered ${match.params.type === 'inspection' ? 'active' : ''}`}>
                        <img className="folder-icon" width="20" src={folderIcon} alt="Folder Icon" />  Inspections
                        <span style={{ float: "right" }} className={recordsRepos.record_folders.inspection_records.count >= 100 ? 'folder-count-blk' : 'folder-count-sm'}>({recordsRepos.record_folders.inspection_records.count})</span>
                      </Link>
                    </li> : <li style={{ background: 'transparent', opacity: '0.5' }}>
                      <Link className={`flex-centered ${match.params.type === 'inspection' ? 'active' : ''}`} style={{ color: '#ffffff', cursor: 'not-allowed' }}>
                        <img className="folder-icon" width="20" src={folderIcon} alt="Folder Icon" /> Inspections
                        <span style={{ float: "right" }} className={recordsRepos?.record_folders?.inspection_records?.count >= 100 ? 'folder-count-blk' : 'folder-count-sm'}>({recordsRepos && recordsRepos.record_folders && recordsRepos.record_folders.inspection_records && recordsRepos.record_folders.inspection_records.count ? recordsRepos.record_folders.inspection_records.count : 0})</span>
                      </Link>
                    </li>
                  }
                  {recordsRepos && recordsRepos.record_folders && recordsRepos.record_folders.contract_records && !recordsRepos.record_folders.contract_records.disable_contracts ?
                    <li onClick={() => this.redirection(`/${match.params.assetType}/${match.params.assetSlug}/data-room/contract/${recordsRepos.record_folders.contract_records.uuid}`, recordsRepos.record_folders.contract_records.uuid)}>
                      <Link className={`flex-centered ${match.params.type === 'contract' ? 'active' : ''}`}>
                        <img className="folder-icon" width="20" src={folderIcon} alt="Folder Icon" />  Contracts
                        <span style={{ float: "right" }} className={recordsRepos?.record_folders?.contract_records?.count >= 1000 ? 'folder-count-blk' : 'folder-count-sm'}>({recordsRepos.record_folders.contract_records.count})</span>
                      </Link>
                    </li> : <li style={{ background: 'transparent', opacity: '0.5' }}>
                      <Link className={`flex-centered ${match.params.type === 'contract' ? 'active' : ''}`} style={match.params.type === 'contract' ?{ color: '#000', cursor: 'not-allowed' }:{ color: '#fff', cursor: 'not-allowed' }}>
                        <img className="folder-icon" width="20" src={folderIcon} alt="Folder Icon" /> Contracts
                        <span style={{ float: "right" }} className={recordsRepos?.record_folders?.contract_records?.count >= 1000 ? 'folder-count-blk' : 'folder-count-sm'}>({recordsRepos && recordsRepos.record_folders && recordsRepos.record_folders.contract_records && recordsRepos.record_folders.contract_records.count ? recordsRepos.record_folders.contract_records.count : 0})</span>
                      </Link>
                    </li>
                  }
                  {recordsRepos && recordsRepos.record_folders && recordsRepos.record_folders.maintanence_records && !recordsRepos.record_folders.maintanence_records.disable_maintenance ?
                    <li onClick={() => this.redirection(`/${match.params.assetType}/${match.params.assetSlug}/data-room/maintanence/${recordsRepos.record_folders.maintanence_records.uuid}`, recordsRepos.record_folders.maintanence_records.uuid)}>
                      <Link className={`flex-centered ${match.params.type === 'maintanence' ? 'active' : ''}`}>
                        <img className="folder-icon" width="20" src={folderIcon} alt="Folder Icon" /> Maintenance
                        <span style={{ float: "right" }} className={recordsRepos?.record_folders?.maintanence_records?.count >= 100 ? 'folder-count-blk' : 'folder-count-sm'}>({recordsRepos.record_folders.maintanence_records.count})</span>
                      </Link>
                    </li> : <li style={{ background: 'transparent', opacity: '0.5' }}>
                      <Link className={`flex-centered ${match.params.type === 'maintanence' ? 'active' : ''}`} style={{ color: '#ffffff', cursor: 'not-allowed' }}>
                        <img className="folder-icon" width="20" src={folderIcon} alt="Folder Icon" /> Maintenance
                        <span style={{ float: "right" }} className={recordsRepos?.record_folders?.maintanence_records?.count >= 100 ? 'folder-count-blk' : 'folder-count-sm'}>({recordsRepos && recordsRepos.record_folders && recordsRepos.record_folders.maintanence_records && recordsRepos.record_folders.maintanence_records.count ? recordsRepos.record_folders.maintanence_records.count : 0})</span>
                      </Link>
                    </li>
                  }
                   {castleLakeInstance ?
               recordsRepos && recordsRepos.record_folders && recordsRepos.record_folders.confidential_records && !recordsRepos.record_folders.confidential_records.disable_confidential ?
                <li onClick={() => this.redirection(`/${match.params.assetType}/${match.params.assetSlug}/data-room/confidential/${recordsRepos.record_folders.confidential_records.uuid}`, recordsRepos.record_folders.confidential_records.uuid)}>
                  <Link className={`flex-centered ${match.params.type === 'confidential' ? 'active':''}`}>
                    <img className="folder-icon" width="20" src={folderIcon} alt="Folder Icon"/> Confidential  
                    <span style={{float:"right"}} className={recordsRepos?.record_folders?.confidential_records?.count >= 100 ? 'folder-count-blk':'folder-count-sm'}>({recordsRepos && recordsRepos.record_folders && recordsRepos.record_folders.confidential_records && recordsRepos.record_folders.confidential_records.count ? recordsRepos.record_folders.confidential_records.count : 0})</span>
                  </Link>
                </li>:<li style={{background: 'transparent', opacity: '0.5'}}>
                  <Link className={`flex-centered ${match.params.type === 'confidential' ? 'active':''}`} style={{color: '#ffffff', cursor: 'not-allowed'}}>
                    <img className="folder-icon" width="20" src={folderIcon} alt="Folder Icon"/> Confidential 
                    <span style={{float:"right"}} className={recordsRepos?.record_folders?.confidential_records?.count >= 100 ? 'folder-count-blk':'folder-count-sm'}>({recordsRepos && recordsRepos.record_folders && recordsRepos.record_folders.confidential_records && recordsRepos.record_folders.confidential_records.count ? recordsRepos.record_folders.confidential_records.count : 0})</span>
                  </Link>
                </li>
              : null}
                  {recordsRepos && recordsRepos.record_folders && recordsRepos.record_folders.discrepancy_records && !recordsRepos.record_folders.discrepancy_records.disable_discripency ?
                    <li onClick={() => this.redirection(`/${match.params.assetType}/${match.params.assetSlug}/data-room/discrepancy/${recordsRepos.record_folders.discrepancy_records.uuid}`, recordsRepos.record_folders.discrepancy_records.uuid)}>
                      <Link className={`flex-centered ${match.params.type === 'discrepancy' ? 'active' : ''}`}>
                        <img className="folder-icon" width="20" src={folderIcon} alt="Folder Icon" /> Discrepancy
                        <span style={{ float: "right" }} className={recordsRepos?.record_folders?.discrepancy_records?.count >= 100 ? 'folder-count-blk' : 'folder-count-sm'}>({recordsRepos && recordsRepos.record_folders && recordsRepos.record_folders.discrepancy_records && recordsRepos.record_folders.discrepancy_records.count ? recordsRepos.record_folders.discrepancy_records.count : 0})</span>
                      </Link>
                    </li> : <li style={{ background: 'transparent', opacity: '0.5' }}>
                      <Link className={`flex-centered ${match.params.type === 'discrepancy' ? 'active' : ''}`} style={{ color: '#ffffff', cursor: 'not-allowed' }}>
                        <img className="folder-icon" width="20" src={folderIcon} alt="Folder Icon" /> Discrepancy
                        <span style={{ float: "right" }} className={recordsRepos?.record_folders?.discrepancy_records?.count >= 100 ? 'folder-count-blk' : 'folder-count-sm'}>({recordsRepos && recordsRepos.record_folders && recordsRepos.record_folders.discrepancy_records && recordsRepos.record_folders.discrepancy_records.count ? recordsRepos.record_folders.discrepancy_records.count : 0})</span>
                      </Link>
                    </li>
                  }
                  {/* {match.params.assetType === 'aircraft' && (getLocalStorageInfo().defaultLessor.id === 13 || getLocalStorageInfo().defaultLessor.id === 68 || getLocalStorageInfo().defaultLessor.id === 321 || getLocalStorageInfo().defaultLessor.id === 405) ? */}
                  {match.params.assetType === 'aircraft' && recordsRepos?.record_folders?.auto_organiser == true ?
                    <li onClick={() => this.redirection(`/${match.params.assetType}/${match.params.assetSlug}/auto-organiser`, '')}>
                      <Link className={`flex-centered ${match.path.includes('auto-organiser') ? 'active' : ''}`}>
                        <img className="folder-icon" width="20" src={folderIcon} alt="Folder Icon" /> Auto Organizer
                      </Link>
                    </li> : null
                  }
                  <li style={checkPermission('records', 'comments_page', 'R') ? {} : { background: 'transparent', opacity: '0.5' }} onClick={(e) => checkPermission('records', 'comments_page', 'R') ? this.redirection(`/${match.params.assetType}/${match.params.assetSlug}/data-room/records_comments`, '') : e.preventDefault()}>
                    <Link className={`flex-centered ${match.path.includes('records_comments') ? 'active' : ''}`}>
                      <CommentIcon size="small" style={{ verticalAlign: 'middle', color: "#75d1f8" }} /> Comments
                    </Link>
                  </li>
                  <li style={checkPermission('records', 'records_issue', 'R') ? {} : { background: 'transparent', opacity: '0.5' }} onClick={(e) => checkPermission('records', 'records_issue', 'R') ? this.redirection(`/${match.params.assetType}/${match.params.assetSlug}/data-room/issues`, '') : e.preventDefault()}>
                    <Link className={`flex-centered ${match.path.includes('issues') ? 'active' : ''}`}>
                      <ReportIcon size="small" style={{ verticalAlign: 'middle', color: "#75d1f8" }} /> Issues
                    </Link>
                  </li>
                  {!titanAviInstance && ! BocInstance ?
                  <li style={checkPermission('records', 'inspection_checklist', 'R') ? {} : { background: 'transparent', opacity: '0.5' }} onClick={(e) => checkPermission('records', 'inspection_checklist', 'R') ? this.redirection(`/${match.params.assetType}/${match.params.assetSlug}/data-room/checklist`, '') : e.preventDefault()}>
                    <Link className={`flex-centered ${match.path.includes('checklist') ? 'active' : ''}`}>
                      <ListAltIcon size="small" style={{ verticalAlign: 'middle', color: "#75d1f8" }} /> Checklist
                    </Link>
                  </li>:null}
                  <li style={checkPermission('records', 'mini-pack', 'R') ? {} : { background: 'transparent', opacity: '0.5' }}  onClick={(e) =>  checkPermission('records', 'mini-pack', 'R') ?this.redirection(`/${match.params.assetType}/${match.params.assetSlug}/data-room/mini-pack`, ''): e.preventDefault() } >
                    <Link className={`flex-centered ${match.path.includes('mini-pack') ? 'active' : ''}`}>
                      <ListAltIcon size="small" style={{ verticalAlign: 'middle', color: "#75d1f8" }} /> Mini Pack
                    </Link>
                  </li>
                </ul>
                </div>
            }
          </Fragment> : null
        }
        {match.path.includes('settings') ?
          <Fragment>
            <ul className="list-unstyled secondary-sidenav">
              <li style={{marginTop:'20px'}}>
                <Link to="/settings/templates" className={match.path.includes('settings/templates') ? 'active' : ''}>Records Template</Link>
              </li>
              <li>
                <Link to="/settings/tags" className={match.path.includes('settings/tags') ? 'active' : ''}>Tags</Link>
              </li>
              <li>
                <Link to="/settings/mini-pack" className={match.path.includes('settings/mini-pack') ? 'active' : ''}>Config Mini Pack</Link>
              </li>
            </ul>
          </Fragment> : null
        }

      </Fragment>
    )
  }
}
const mapStateToProps = state => ({
  recordsRepos: state.shareReducer.recordsRepos,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getRecordsRepos: () => dispatch(getRecordsReposAc(ownProps)),
  }
}
export default withRouter(withSnackbar(connect(mapStateToProps, mapDispatchToProps)(SecondarySideNav)));
